import { ReactNode, useCallback, MouseEvent, useState } from 'react';
import { useUser, useSetAlert, useProcessTemplateMutation } from '@tymely/services';
import { Allotment } from 'allotment';
import { Button, styled, Box, CircularProgress } from '@mui/material';
import ForwardIcon from '@mui/icons-material/Forward';
import { useNavigate } from 'react-router-dom';
import { useHistoryStack } from '@tymely/utils';

import TemplateCodeEditor from './TemplateCodeEditor';
import TemplateRichText from './TemplateRichText';
import TemplateSideBar from './TemplateSideBar';
import TemplateEditorProvider, { TemplateEditorProviderProps } from './TemplateEditorProvider';
import TemplateViewers from './TemplateViewers';
import { useTemplateEditor } from './TemplateEditorProvider';

import 'allotment/dist/style.css';

const _BackButton = styled(Button)`
    border-radius: 0;
    .MuiButton-startIcon {
        transform: rotate(-180deg);
    }
`;

const CenteredBox = styled(Box)({
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'text.secondary',
});

const BackButton = () => {
    const navigations = useHistoryStack();
    const navigate = useNavigate();

    if (navigations.length <= 0) {
        return null;
    }

    return (
        <_BackButton fullWidth variant="contained" startIcon={<ForwardIcon />} onClick={() => navigate(-1)}>
            Back
        </_BackButton>
    );
};

type TemplateEditorProps = TemplateEditorProviderProps & {
    withBackButton?: boolean;
    editorMode: 'prompt' | 'template';
    onModeChange?: (event: MouseEvent<HTMLElement>, newMode: 'prompt' | 'template') => void;
};

const TemplateEditor = ({ withBackButton, children, editorMode, ...rest }: TemplateEditorProps) => {
    const user = useUser();

    if (!user?.isAdmin) {
        return <div>Access denied</div>;
    }

    return (
        <TemplateEditorProvider {...rest}>
            <TemplateEditorContent withBackButton={withBackButton} editorMode={editorMode}>
                {children}
            </TemplateEditorContent>
        </TemplateEditorProvider>
    );
};

const TemplateEditorContent = ({
    withBackButton,
    children,
    editorMode = 'template',
}: {
    withBackButton?: boolean;
    children?: ReactNode;
    editorMode: 'prompt' | 'template';
}) => {
    const showAlert = useSetAlert();
    const processTemplateMutation = useProcessTemplateMutation();
    const { renderTemplateQuery, comment } = useTemplateEditor();
    const [processedResult, setProcessedResult] = useState<string>('');
    const [isProcessing, setIsProcessing] = useState(false);

    const handleRunClick = useCallback(async () => {
        if (!comment?.[0]?.value || !renderTemplateQuery.data) {
            showAlert('Please select a comment and ensure template is rendered', 'error');
            return;
        }

        try {
            setIsProcessing(true);
            setProcessedResult('');
            const response = await processTemplateMutation.mutateAsync({
                response_txt: renderTemplateQuery.data,
                comment_id: comment[0].value,
            });
            setProcessedResult(response.replace(/\n/g, '<br />'));
            showAlert('Template processed successfully', 'success');
        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : 'Failed to process template';
            showAlert(errorMessage, 'error');
            console.error('Failed to process template:', error);
        } finally {
            setIsProcessing(false);
        }
    }, [processTemplateMutation, comment, renderTemplateQuery.dataUpdatedAt, showAlert]);

    return (
        <Allotment>
            <Allotment.Pane preferredSize="25%" minSize={300}>
                <TemplateSideBar>{withBackButton && <BackButton />}</TemplateSideBar>
            </Allotment.Pane>
            <Allotment.Pane preferredSize="75%">
                <Allotment vertical>
                    <Allotment.Pane minSize={300}>
                        <TemplateCodeEditor>{children}</TemplateCodeEditor>
                        <TemplateViewers />
                    </Allotment.Pane>
                    <Allotment.Pane snap={true}>
                        {editorMode === 'template' ? (
                            <TemplateRichText />
                        ) : (
                            <Allotment>
                                <Allotment.Pane>
                                    <Box sx={{ position: 'relative', height: '100%' }}>
                                        <TemplateRichText />
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                bottom: 16,
                                                right: 16,
                                                zIndex: 1,
                                            }}
                                        >
                                            <Button
                                                variant="contained"
                                                onClick={handleRunClick}
                                                disabled={isProcessing}
                                                sx={{
                                                    bgcolor: 'warning.main',
                                                    '&:hover': {
                                                        bgcolor: 'warning.dark',
                                                    },
                                                }}
                                            >
                                                {isProcessing ? 'Processing...' : 'Run'}
                                            </Button>
                                        </Box>
                                    </Box>
                                </Allotment.Pane>
                                <Allotment.Pane>
                                    <Box
                                        sx={{
                                            height: '100%',
                                            bgcolor: 'background.paper',
                                            position: 'relative',
                                            p: 2,
                                            overflow: 'auto',
                                        }}
                                    >
                                        {isProcessing ? (
                                            <CenteredBox>
                                                <CircularProgress size={20} sx={{ mr: 1 }} />
                                                Processing template...
                                            </CenteredBox>
                                        ) : processedResult ? (
                                            <div dangerouslySetInnerHTML={{ __html: processedResult }} />
                                        ) : (
                                            <CenteredBox>Run the template to see the result</CenteredBox>
                                        )}
                                    </Box>
                                </Allotment.Pane>
                            </Allotment>
                        )}
                    </Allotment.Pane>
                </Allotment>
            </Allotment.Pane>
        </Allotment>
    );
};

export default TemplateEditor;

import { Autocomplete, SxProps, TextField } from '@mui/material';
import { useLlmModelMetadatasQuery } from '@tymely/services';
import React, { memo, useMemo } from 'react';
import { Theme } from '@mui/system';
import RenderHighlightedOption from '@tymely/components/RenderHighlightedOption';

type LlmModelDropdownProps = {
    value: number | null;
    onChange: (value: number | null) => void;
    onBlur?: React.FocusEventHandler<HTMLElement>;
    name?: string;
    disabled?: boolean;
    inputRef?: React.Ref<HTMLInputElement>;
    sx?: SxProps<Theme>;
};

const LlmModelDropdown = ({ value, onChange, onBlur, name, disabled, inputRef, sx }: LlmModelDropdownProps) => {
    const { data, isLoading } = useLlmModelMetadatasQuery();
    const options = useMemo(() => {
        return isLoading
            ? []
            : data.map((item) => ({
                  id: item.id,
                  label: item.title || item.llm_model_id,
              }));
    }, [isLoading, data]);

    return (
        <Autocomplete
            aria-label="model-input"
            options={options}
            sx={sx}
            getOptionLabel={(option) => option.label}
            value={options.find((opt) => opt.id === value) || null}
            onChange={(_, newValue) => {
                onChange(newValue?.id);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    size="small"
                    label="Model"
                    sx={{
                        minWidth: 160,
                        textAlign: 'left',
                        ...sx,
                    }}
                    value={value}
                    onBlur={onBlur}
                    name={name}
                    disabled={disabled}
                    inputRef={inputRef}
                />
            )}
            renderOption={(props, option, { inputValue }) => (
                <RenderHighlightedOption props={props} option={option} inputValue={inputValue} />
            )}
            filterOptions={(opts, { inputValue }) =>
                opts.filter((opt) => opt.label.toLowerCase().includes(inputValue.toLowerCase()))
            }
        />
    );
};

export default memo(LlmModelDropdown, (prevProps, nextProps) => {
    return (
        prevProps.value === nextProps.value &&
        prevProps.onChange === nextProps.onChange &&
        prevProps.sx === nextProps.sx
    );
});

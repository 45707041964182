import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { AuthUser, fetchAuthSession, fetchUserAttributes, getCurrentUser, signOut } from '@aws-amplify/auth';
import { userAtom } from '@tymely/atoms';

import { useSetAlert } from './alerts.services';

export const useUser = () => useRecoilValue(userAtom);

export const useSetUser = () => useSetRecoilState(userAtom);

export const useAuthenticate = () => {
    const setUser = useSetUser();
    const user = useUser();
    const setAlert = useSetAlert();

    return {
        authenticate: useCallback(async () => {
            const cognitoUser: AuthUser = await getCurrentUser();
            const session = await fetchAuthSession({ forceRefresh: true });
            if (!session) return signOut();
            const userGroups: string[] = (session.tokens?.accessToken.payload['cognito:groups'] as string[]) || [];
            const userAttributes = await fetchUserAttributes();
            const userEmail = userAttributes.email;
            if (!userEmail) {
                setAlert('User must set email.', 'error');
                return;
            }

            setUser({
                username: cognitoUser.username,
                isAdmin: userGroups.includes('admins'),
                email: userEmail,
            });
            if (cognitoUser.username) {
                datadogRum.setUser({
                    name: cognitoUser.username,
                });
                datadogLogs.setGlobalContextProperty('username', cognitoUser.username);
            }
        }, [setUser]),
        user,
    };
};

export const SignOut = () => signOut({ global: true }).then(() => window.location.reload());

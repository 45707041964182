import { useCallback, useMemo } from 'react';
import { AxiosError } from 'axios';
import { useIsMutating, useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiError, ArgumentUsedError, useApi, TicketIsLockedError } from '@tymely/api';
import {
    ArgMdActionUsageDetails,
    ArgMetadatadInUseDetails,
    ArgMetadataUsage,
    IArgument,
    IArgumentMetadata,
    IArgumentExtractorInfo,
    dType,
    IArgumentUpdate,
    sortArgs,
    IComment,
    normalizeDtype,
    applyArgUpdates,
    isPolicyEvaluationRunning,
} from '@tymely/atoms';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useSetAlert } from './alerts.services';
import {
    useAgentResponse,
    useSelectedCommentDecisionQuery,
    useSelectedComment,
    useSetHistoricAnalysis,
    DECISION_QUERY_KEY,
    AGENT_RESPONSE_QUERY_KEY,
    ARGUMENTS_QUERY_KEY,
} from './comment.services';
import { AppMode, useAppMode } from './mode';
import { useUser } from './auth.services';
import { useIsTicketLocked, useTicket } from './ticket.services';

const ARGUMENTS_METADATA_QUERY_KEY = 'argumentsMetadata';
const ARGUMENT_EXTRACTORS_QUERY_KEY = 'argExtractorNames';
const ARGUMENT_DTYPE_HINTS_QUERY_KEY = 'argExtractorDTypes';
const DTYPE_SCHEMA_QUERY_KEY = 'dtypeSchema';
const APPROVE_ARGUMENT_MUTATION_KEY = 'approveArgument';

interface EditArgumentByCommentIdParams {
    argUpdatesByMetadataId: IArgumentUpdate[];
    commentId: number;
    run_async: boolean;
}

interface EditArgumentByCommentIdResults {
    didEditArguments?: boolean;
    missingMetadataIds: number[];
}

export function useFetchCommentArguments() {
    const api = useApi();
    return async (commentId: IComment['id'], version?: string, onlyIntentArgs = true) => {
        const args = (await api.get(`comment/${commentId}/arguments`, {
            params: {
                as_seen_at: version,
                intent_args_only: onlyIntentArgs,
            },
        })) as IArgument[];
        return args.map((arg) => {
            arg.dtype = normalizeDtype(arg.dtype);
            return arg;
        });
    };
}

export const useEditArgumentsByMetadataIdsMutation = (opts: {
    onSuccess?: (args: EditArgumentByCommentIdResults) => void;
    onError?: (message?: string) => void;
}) => {
    const api = useApi();
    const setAlert = useSetAlert();
    const queryClient = useQueryClient();
    const { appMode } = useAppMode();
    const fetchCommentArguments = useFetchCommentArguments();

    return useMutation<EditArgumentByCommentIdResults, AxiosError, EditArgumentByCommentIdParams>({
        mutationFn: async ({ argUpdatesByMetadataId, commentId, run_async }) => {
            const commentArguments = await fetchCommentArguments(commentId);
            const commentArgumentIdByMetadataId = new Map(commentArguments.map((arg) => [arg.md_id, arg.id]));
            const missingMetadataIds: number[] = [];
            const argUpdates = argUpdatesByMetadataId
                .map((argUpdateByMetadataId) => {
                    const argId = commentArgumentIdByMetadataId.get(argUpdateByMetadataId.id);
                    if (argId === undefined) {
                        missingMetadataIds.push(argUpdateByMetadataId.id);
                        return undefined;
                    }
                    return {
                        ...argUpdateByMetadataId,
                        id: argId,
                    };
                })
                .filter(Boolean);

            if (argUpdates.length > 0) {
                await api.put(
                    'arguments/edit',
                    {
                        update_data: argUpdates,
                        update_argument_tree: appMode !== AppMode.QA,
                    },
                    {
                        params: {
                            comment_id: commentId,
                            async: run_async,
                        },
                    },
                );
            }

            return {
                didEditArguments: argUpdates.length > 0,
                missingMetadataIds,
            };
        },
        onSuccess: async (data, { commentId }) => {
            if (appMode !== AppMode.QA) {
                await Promise.all([
                    queryClient.invalidateQueries({ queryKey: [DECISION_QUERY_KEY, commentId] }),
                    queryClient.invalidateQueries({ queryKey: [AGENT_RESPONSE_QUERY_KEY, commentId] }),
                ]);
            }
            return opts.onSuccess?.(data);
        },
        onError: (error, { commentId, argUpdatesByMetadataId }) => {
            opts.onError?.(error.message);
            setAlert(
                error.message,
                'error',
                5000,
                `Failed editing comment ${commentId} argument metadatas (${argUpdatesByMetadataId
                    .map((v) => v.id)
                    .join(', ')}).`,
            );
        },
        retry: 1,
    });
};

export const EDIT_ARGUMENTS_MUTATION_KEY = 'editArguments';

export const useEditArgumentsMutation = (opts?: {
    onSuccess?: () => void;
    onError?: (message?: string) => void;
    onStatus?: (status?: string) => void;
    version?: string;
}) => {
    const setAlert = useSetAlert();
    const selectedComment = useSelectedComment();
    const queryClient = useQueryClient();
    const { appMode } = useAppMode();

    const api = useApi();
    const setPolicyEvaluationRunning = useSetRecoilState(isPolicyEvaluationRunning);
    const ticket = useTicket();
    const isTicketLockedQuery = useIsTicketLocked(ticket.id);

    return useMutation<IArgument[], AxiosError, IArgumentUpdate[]>({
        mutationKey: [EDIT_ARGUMENTS_MUTATION_KEY],
        mutationFn: (updates) => {
            if (!selectedComment) {
                return Promise.reject('Comment ID is not defined.');
            }

            return api.put(
                'arguments/edit',
                {
                    update_data: updates,
                    update_argument_tree: true,
                },
                {
                    params: {
                        comment_id: selectedComment.id,
                        async: appMode !== AppMode.QA,
                    },
                },
            );
        },
        onMutate() {
            opts?.onStatus?.('Saving arguments...');
        },
        onSuccess: (_, updates) => {
            if (!selectedComment) {
                return;
            }
            const queryKey = opts?.version
                ? [ARGUMENTS_QUERY_KEY, selectedComment.id, opts.version]
                : [ARGUMENTS_QUERY_KEY, selectedComment.id];

            queryClient.setQueryData(queryKey, (cache?: IArgument[]) => applyArgUpdates(cache || [], updates));

            setPolicyEvaluationRunning(true);
            isTicketLockedQuery.refetch().then(({ data: isLocked }) => {
                if (isLocked) {
                    setPolicyEvaluationRunning(false);
                }
            });

            opts?.onStatus?.(undefined);
            return opts?.onSuccess?.();
        },
        onError: (error, variables) => {
            if (error instanceof TicketIsLockedError) {
                return;
            }
            opts?.onError?.(error.message);
            setAlert(
                error.message,
                'error',
                5000,
                `Failed editing arguments (${variables.map((v) => v.id).join(', ')}).`,
            );
        },
    });
};

export const useArgumentsMetadataQuery = () => {
    const api = useApi();
    return useQuery<IArgumentMetadata[]>({
        queryKey: [ARGUMENTS_METADATA_QUERY_KEY],
        queryFn: () => api.get('argument-metadatas/list', { params: { limit: 3000 } }),
        staleTime: Infinity,
        meta: {
            errorMessage: 'Failed fetching arguments metadata',
        },
    });
};

export const useArgExtractorsQuery = () => {
    const api = useApi();
    return useQuery<IArgumentExtractorInfo[]>({
        queryKey: [ARGUMENT_EXTRACTORS_QUERY_KEY],
        queryFn: () => api.get('argument-extractors'),
        meta: {
            errorMessage: 'Failed fetching argument extractor names',
        },
        staleTime: Infinity,
    });
};

export const useDTypeHintsQuery = () => {
    const api = useApi();
    return useQuery<dType[]>({
        queryKey: [ARGUMENT_DTYPE_HINTS_QUERY_KEY],
        queryFn: async () => {
            const types = (await api.get('schema/dtypes')) as dType[];
            return [...types, ...types.map((type) => `list[${type}]`)] as dType[];
        },
        staleTime: Infinity,
    });
};

export const CREATE_ARG_METADATA_MUTATION_KEY = 'create-arg-metadata';

export const useCreateArgumentMetadataMutation = (onSuccess?: (arg: IArgumentMetadata) => void) => {
    const api = useApi();
    const setAlert = useSetAlert();
    const queryClient = useQueryClient();
    const user = useUser();

    return useMutation<IArgumentMetadata, AxiosError, { metadata: Omit<IArgumentMetadata, 'id'> }>({
        mutationKey: [CREATE_ARG_METADATA_MUTATION_KEY],
        mutationFn: (params) =>
            api.post('argument-metadata', {
                ...params.metadata,
                additional_data: {
                    ...params.metadata.additional_data,
                    created_by: user?.username,
                },
            }),
        onSuccess: (data) => {
            if (!data.deleted_at) {
                queryClient.setQueryData([ARGUMENTS_METADATA_QUERY_KEY], (cache?: IArgumentMetadata[]) =>
                    cache ? cache.concat(data) : [data],
                );
            }
            onSuccess?.(data);
        },
        onError: (_, variables) => {
            setAlert(`Failed creating argument "${variables.metadata.name}"`, 'error');
        },
    });
};

function formatMissingArgDetailsItem(details: ArgMetadataUsage | ArgMdActionUsageDetails) {
    const detailsStr = `&nbsp;&nbsp;org_id=${details.organization_id}, policy_set_id=${details.policy_set_id}, workflow_id=${details.workflow_id}`;
    return 'path' in details && details.path ? `${detailsStr}, action_arg=${details.path}` : detailsStr;
}

function formatMissingArgDetails(
    title: string,
    details: (ArgMetadataUsage | ArgMdActionUsageDetails)[],
): string | null {
    if (details.length === 0) {
        return null;
    }
    return `<strong>${title}</strong><br/>${details.map(formatMissingArgDetailsItem).join('<br/>')}`;
}

export const useEditArgumentMetadataMutation = () => {
    const api = useApi();
    const setAlert = useSetAlert();
    const queryClient = useQueryClient();

    return useMutation<IArgumentMetadata, AxiosError, IArgumentMetadata>({
        mutationFn: (metadata) => api.put(`argument-metadata/${metadata.id}`, metadata),
        onSuccess: (data) => {
            queryClient.setQueryData([ARGUMENTS_METADATA_QUERY_KEY], (cache?: IArgumentMetadata[]) =>
                cache ? cache.map((arg) => (arg.id === data.id ? data : arg)) : [data],
            );
        },
        onError: (error: AxiosError, variables) => {
            const errorMessage =
                error instanceof ArgumentUsedError
                    ? '<strong>Argument is being used:</strong><br/>' +
                      [
                          formatMissingArgDetails('Templates', error.detail.templates),
                          formatMissingArgDetails('Workflows', error.detail.policies),
                          formatMissingArgDetails('Action args', error.detail.actions),
                      ]
                          .filter(Boolean)
                          .join('<br/><br/>')
                    : error instanceof ApiError
                      ? error.detail
                      : '';

            setAlert(errorMessage, 'error', 0, `Failed saving argument "${variables.name}" (id=${variables.id})`);
        },
    });
};

export const useDeleteArgumentMetadataMutation = () => {
    const api = useApi();
    const setAlert = useSetAlert();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (metadata: IArgumentMetadata) => api.delete(`argument-metadata/${metadata.id}`),
        onSuccess: (_, input) => {
            queryClient.setQueryData([ARGUMENTS_METADATA_QUERY_KEY], (cache?: IArgumentMetadata[]) =>
                cache ? cache.filter((arg) => arg.id !== input.id) : [],
            );
        },
        onError: (_, variables) => {
            setAlert(`Failed deleting argument "${variables.name}"`, 'error');
        },
    });
};

export const usePoliciesUsageQuery = (argNamesChunks: IArgumentMetadata['name'][][]) => {
    const api = useApi();
    const queries = [];
    for (const argNames of argNamesChunks) {
        queries.push({
            queryKey: ['argsUsage', ...argNames],
            queryFn: () =>
                api.get(`arg-usage?${argNames.map((a) => `arg_name=${a}`).join('&')}`) as Promise<
                    Record<IArgumentMetadata['name'], ArgMetadatadInUseDetails>
                >,
            retry: false,
        });
    }
    return useQueries({ queries });
};

export const ARGUMENT_VERSIONS_QUERY_KEY = 'argumentVersions';

export const useArgumentVersionsQuery = () => {
    const api = useApi();
    const selectedComment = useSelectedComment();
    const commentId = selectedComment?.id;
    return useQuery<string[], AxiosError>({
        queryKey: [ARGUMENT_VERSIONS_QUERY_KEY, commentId],
        queryFn: () => (commentId ? api.get(`comment/${commentId}/arguments/versions`) : Promise.reject([])),
        enabled: !!commentId,
        retry: (_, error) => {
            return !(error instanceof ApiError);
        },
    });
};

export const useArgumentsQuery = (opts: {
    commentId?: number;
    argsVersion?: string;
    enabled?: boolean;
    intentArgsOnly?: boolean;
}) => {
    const enabled = Boolean((opts.enabled ?? true) && opts.commentId);
    const { updateDecision, data: decision } = useSelectedCommentDecisionQuery(enabled && !opts.argsVersion);
    const { updateResponse } = useAgentResponse();
    const setHistoricAnalysis = useSetHistoricAnalysis();
    const api = useApi();
    const fetchCommentArguments = useFetchCommentArguments();
    const queryClient = useQueryClient();

    const updateArguments = useCallback(
        (updatedArgs: IArgument[], replace = false) =>
            queryClient.setQueryData<IArgument[] | undefined>(
                opts.argsVersion
                    ? [ARGUMENTS_QUERY_KEY, opts.commentId, opts.argsVersion]
                    : [ARGUMENTS_QUERY_KEY, opts.commentId],
                replace
                    ? updatedArgs
                    : (args?: IArgument[]) => args?.map((arg) => updatedArgs.find(({ id }) => id === arg.id) || arg),
            ),
        [queryClient, opts.commentId, opts.argsVersion],
    );

    const query = useQuery<IArgument[], AxiosError | ApiError>({
        queryKey: opts.argsVersion
            ? [ARGUMENTS_QUERY_KEY, opts.commentId, opts.argsVersion]
            : [ARGUMENTS_QUERY_KEY, opts.commentId],
        queryFn: async () => {
            if (!opts.commentId) {
                return Promise.reject('Comment ID is not defined.');
            }
            const args = await fetchCommentArguments(opts.commentId, opts.argsVersion, opts.intentArgsOnly !== false);
            if (opts.commentId && opts.argsVersion) {
                const comment = (await api.post(`comment/${opts.commentId}/evaluate-policy`, args)) as IComment;
                if (comment.selected_intent_id) {
                    updateDecision(comment.additional_data.decisions[comment.selected_intent_id]['decision']);
                    updateResponse(comment.response_body || '');
                    setHistoricAnalysis(comment);
                }
            }
            return args;
        },
        enabled,
        select(args) {
            args = sortArgs(
                args.map((arg) => {
                    arg.dtype = normalizeDtype(arg.dtype);
                    return arg;
                }),
            );
            return args;
        },
        retry: false,
        staleTime: Infinity,
    });

    const decisionParticipatingArgs = useMemo(() => {
        const lineage = decision?.decision_lineage;
        const isGraphPolicy = !!query.data?.[0]?.dependent_node_ids;
        if (!query.data || !lineage?.length || !isGraphPolicy) {
            return query.data ?? [];
        }

        return query.data.filter((arg) => {
            return !!arg.dependent_node_ids?.some((nodeId) => lineage.includes(nodeId));
        });
    }, [decision, query.data]);

    return { ...query, data: decisionParticipatingArgs, updateArguments };
};

export const useArgumentSchemaQuery = (dtype?: string) => {
    const api = useApi();
    return useQuery({
        queryKey: [DTYPE_SCHEMA_QUERY_KEY, dtype],
        queryFn: () => (dtype ? api.get(`schema/dtype/${dtype}`) : Promise.reject('dtype is not provided')),
        enabled: !!dtype,
    });
};

export const useApproveArgumentMutation = (
    commentId: IComment['id'],
    opts?: {
        onSuccess?: () => void;
        onError?: (message?: string) => void;
    },
) => {
    const api = useApi();
    const queryClient = useQueryClient();

    return useMutation<EditArgumentByCommentIdResults, AxiosError, IArgument>({
        mutationKey: [APPROVE_ARGUMENT_MUTATION_KEY],
        mutationFn: (argument) => api.put(`argument/${argument.id}/approve`),
        onMutate(argument) {
            // Optimistic update
            const args = queryClient.getQueryData<IArgument[]>([ARGUMENTS_QUERY_KEY, commentId]);
            if (args) {
                queryClient.setQueryData<IArgument[]>(
                    [ARGUMENTS_QUERY_KEY, commentId],
                    args.map((arg) => {
                        if (arg.id === argument.id) {
                            return {
                                ...arg,
                                approved_at: String(new Date()),
                            };
                        }
                        return arg;
                    }),
                );
            }
        },
        onSuccess: opts?.onSuccess,
        onError: (error, argument) => {
            // Rollback
            const args = queryClient.getQueryData<IArgument[]>([ARGUMENTS_QUERY_KEY, commentId]);
            if (args) {
                queryClient.setQueryData<IArgument[]>(
                    [ARGUMENTS_QUERY_KEY, commentId],
                    args.map((arg) => {
                        if (arg.id === argument.id) {
                            return {
                                ...arg,
                                approved_at: null,
                            };
                        }
                        return arg;
                    }),
                );
            }
            opts?.onError?.(error.message);
        },
        retry: 1,
    });
};

export function useTicketLoadingStatus() {
    const ticket = useTicket();
    const policyEvaluationRunning = useRecoilValue(isPolicyEvaluationRunning);
    const decisionQuery = useSelectedCommentDecisionQuery();
    const selectedComment = useSelectedComment();
    const argsQuery = useArgumentsQuery({
        commentId: selectedComment?.selected_intent_id ? selectedComment?.id : undefined,
    });
    const { data: isTicketLocked } = useIsTicketLocked(ticket.id);
    const isMutating = useIsMutating({ mutationKey: [EDIT_ARGUMENTS_MUTATION_KEY] }) > 0;

    const asyncActionStatus = [
        isMutating && 'Saving arguments.',
        policyEvaluationRunning && 'Evaluating policy.',
        decisionQuery.isFetching && 'Fetching decision.',
        argsQuery.isFetching && 'Fetching arguments.',
    ]
        .filter(Boolean)
        .join(' ');

    const lockTicketStatus = isTicketLocked && 'The ticket is being processed in the background, please wait.';

    return asyncActionStatus || lockTicketStatus || undefined;
}

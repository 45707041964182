import { Box, Button, styled, Typography, TypographyProps } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { LlmTrailEvent } from '@tymely/atoms/llmTrail.atoms';
import JsonView from '@tymely/components/JsonView';

const CustomButton = styled(Button)(() => ({
    cursor: 'pointer',
    color: 'purple',
    borderColor: 'purple',
    '&:hover': {
        color: 'black',
        borderColor: 'black',
    },
}));

export const _Expanded = styled(Box)`
    margin: ${({ theme }) => theme.spacing(-1, 0, 1, 0)};
`;

export const _JsonContainer = styled(Box)`
    margin-bottom: ${({ theme }) => theme.spacing(1)};
    font-family: monospace;
`;

const _AgentBubble = styled(Box)`
    background-color: ${({ theme }) => theme.colorSchemes.light.palette.agent};
    padding: ${({ theme }) => theme.spacing(1)};
    border-radius: ${({ theme }) => theme.spacing(1)};
    margin-top: ${({ theme }) => theme.spacing(2)};
    margin-bottom: ${({ theme }) => theme.spacing(2)};
    color: #000;
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    &::after {
        content: '';
        position: absolute;
        bottom: -19px;
        left: 50%;
        transform: translateX(-50%);
        border-width: 10px;
        border-style: solid;
        border-color: ${({ theme }) => theme.colorSchemes.light.palette.agent} transparent transparent transparent;
    }
`;

type EventItemProps = TypographyProps & {
    event: LlmTrailEvent;
};

const AgentBox = ({ event }: EventItemProps) => {
    const [isExpandedMessages, setIsExpandedMessages] = useState(false);
    const [showMessages, setShowMessages] = useState(false);
    const [showFuncArguments, setShowFuncArguments] = useState<{ open: boolean; arguments: any; name: string }>({
        open: false,
        arguments: null,
        name: '',
    });

    const nonCollapsedMessages = useMemo(() => {
        const set = new Set<number>();
        for (let i = event.request.messages.length - 1; i >= 0; i--) {
            if (event.request.messages[i].role !== 'ASSISTANT') {
                break;
            }
            set.add(i);
        }
        return set;
    }, [event.request.messages]);

    return (
        <>
            <_AgentBubble>
                <Typography variant="body2" sx={{ position: 'absolute', top: 8, right: 8 }}>
                    {event.response.version}
                </Typography>
                <Typography variant="body2" sx={{ position: 'absolute', top: 8, left: 8 }}>
                    {event?.request?.template_name}
                </Typography>
                <CustomButton variant="outlined" onClick={() => setShowMessages((prevState) => !prevState)}>
                    Context
                </CustomButton>
                {showMessages && (
                    <Box sx={{ marginTop: '5px' }}>
                        <CustomButton variant="outlined" onClick={() => setIsExpandedMessages(!isExpandedMessages)}>
                            {isExpandedMessages ? 'Show Less' : 'Show More'}
                        </CustomButton>
                        {event.request.messages
                            .filter((_, index) => nonCollapsedMessages.has(index) || isExpandedMessages)
                            .map((message, index) => (
                                <>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                        {message.role}
                                    </Typography>
                                    <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
                                        {message.content}
                                    </Typography>
                                    {index < event.request.messages.length - 1 && (
                                        <Box sx={{ borderBottom: '1px solid #000', margin: '5px 0' }} />
                                    )}
                                </>
                            ))}
                    </Box>
                )}
                {event.raw_response !== 'func_calls' && (
                    <Typography sx={{ marginTop: '5px' }} variant="body2">
                        {event.raw_response}
                    </Typography>
                )}
                {event.response?.func_calls && (
                    <Box sx={{ marginTop: '5px' }}>
                        {event.response.func_calls.map((call, index) => (
                            <CustomButton
                                variant="outlined"
                                key={index}
                                onClick={() =>
                                    setShowFuncArguments((prevState) => ({
                                        open: prevState.name !== call.name || !prevState.open,
                                        arguments: call.arguments,
                                        name: call.name,
                                    }))
                                }
                            >
                                {call.name}
                            </CustomButton>
                        ))}
                    </Box>
                )}
            </_AgentBubble>
            {showFuncArguments.open && (
                <_Expanded>
                    <_JsonContainer>
                        <JsonView data={showFuncArguments.arguments} shouldExpandNode={() => true} />
                    </_JsonContainer>
                </_Expanded>
            )}
        </>
    );
};

export default AgentBox;

import { memo, useCallback, useState, useMemo, useEffect, forwardRef, ForwardedRef, useRef } from 'react';
import { styled, List, Box, IconButtonProps, BoxProps, Typography, useTheme } from '@mui/material';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { TextareaAutosize as TextareaAutosizeBase, TextareaAutosizeProps } from '@mui/base/TextareaAutosize';
import { Popper, PopperProps } from '@mui/base/Popper';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useNotes, useUser } from '@tymely/services';
import { INote, IReportNote } from '@tymely/atoms';
import UpIcon from '@tymely/components/Icons/Up';
import { Avatar } from '@tymely/components/Avatar';

import { IconButton } from './';

import { Title, formatDate, DateText } from './TicketsHistory';

export const Input = styled((props: TextareaAutosizeProps) => {
    return <TextareaAutosizeBase {...props} />;
})(
    ({ theme }) => `
        width: 100%;
        max-height: 100%;
        flex: 1;
        overflow: scroll !important;
        resize: none;
        &::placeholder {
            color: ${theme.palette.text.disabled};
        }
        outline: none;
        background-color: inherit;
        border: none;
        padding: 0;
`,
);

const NotesList = styled(List)(
    ({ theme }) => `
    height: 100%;
    margin: 0;
    overflow: auto;
    padding: ${theme.spacing(0, 3)};
`,
);

const SendButton = styled((props: IconButtonProps) => (
    <IconButton {...props}>
        <UpIcon />
    </IconButton>
))`
    background-color: var(--ty-palette-common-selected);
    color: var(--ty-palette-common-white);
    &:hover {
        background-color: var(--ty-palette-common-selected);
    }
`;

const ExpandButton = styled((props: BoxProps) => (
    <Box {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
            <path d="M1 7L7 1" stroke="#6D73A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M2.5 1H7V5.5" stroke="#6D73A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </Box>
))(
    ({ theme }) => `
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${theme.spacing(3)};
    width: ${theme.spacing(3)};;
    cursor: pointer;
`,
);

const CollapseButton = styled((props: BoxProps) => <ExpandButton {...props} />)`
    transform: rotate(180deg);
`;

export const NoteExtendedPopper = styled(
    (
        props: Omit<PopperProps, 'open'> & {
            note?: INote;
            anchorEl?: Element;
            disabled?: boolean;
            onClose: () => void;
            onSave: (text: string) => Promise<unknown>;
        },
    ) => {
        const [text, setText] = useState(props.note?.text || '');

        useEffect(() => {
            setText(props.note?.text || '');
        }, [props.note]);

        const onSave = useCallback(async () => {
            await props.onSave(text);
            props.onClose();
        }, [text, props.onSave, props.onClose]);

        const { onSave: onSaveProp, disabled, note, ...rest } = props;

        return (
            <Popper
                {...rest}
                open={!!props.note}
                anchorEl={props.anchorEl}
                placement="top-start"
                modifiers={[
                    {
                        name: 'flip',
                        options: {
                            fallbackPlacements: [],
                        },
                    },
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 16],
                        },
                    },
                ]}
            >
                <CollapseButton onClick={props.onClose} />
                <ClickAwayListener onClickAway={props.onClose}>
                    <Box display="flex" flexDirection="column" height={1}>
                        {props.note?.username && (
                            <Box display="flex" alignItems="center" mb={4}>
                                <Avatar size="large" marginRight={1} username={props.note.username} />
                                <Typography variant="subtitle2" textTransform="capitalize">
                                    {props.note.username}
                                </Typography>
                            </Box>
                        )}
                        <Input
                            value={text}
                            autoFocus
                            readOnly={props.disabled}
                            onChange={(e) => setText(e.target.value)}
                        />
                        <Box display="flex" justifyContent="flex-end" alignItems="center">
                            {props.note?.datetime && <DateText>{formatDate(props.note.datetime)}</DateText>}
                            {!props.disabled && <SendButton disabled={!text} sx={{ ml: 1 }} onClick={onSave} />}
                        </Box>
                    </Box>
                </ClickAwayListener>
            </Popper>
        );
    },
)(
    ({ theme }) => `
    width: ${theme.spacing(62)};
    height: ${theme.spacing(44)};
    padding: ${theme.spacing(4)};
    padding-bottom: ${theme.spacing(3)};
    border-radius: ${theme.shape.borderRadius}px;
    background: ${theme.components?.Input?.backgroundColor};
    box-shadow: 28px 27px 33px 0px rgba(38, 69, 204, 0.10);
    z-index: ${theme.zIndex.modal};
    border: 3px solid ${theme.palette.common.white};
`,
);

const NoteInput = styled(
    (
        props: BoxProps & {
            note?: INote;
            readOnly?: boolean;
            onExpand: (elem: HTMLLIElement, note: INote) => void;
            onSave?: (note: string) => Promise<unknown>;
        },
    ) => {
        const ref = useRef<HTMLLIElement>(null);

        const [noteText, setNoteText] = useState(props.note?.text || '');
        useEffect(() => {
            setNoteText(props.note?.text || '');
        }, [props.note]);

        const onSave = useCallback(async () => {
            await props.onSave?.(noteText);
            setNoteText('');
        }, [noteText, props.onSave]);

        return (
            <Box position="relative" component="li" mb={2} className={props.className} ref={ref}>
                <ExpandButton
                    onClick={() => ref.current && props.onExpand(ref.current, props.note || { text: noteText })}
                />
                <Input
                    value={noteText}
                    readOnly={props.readOnly}
                    placeholder="Add new note"
                    onChange={(e) => setNoteText(e.target.value)}
                />
                <Box pb={2} display="flex" alignItems="center">
                    {props.note ? (
                        <>
                            {props.note?.username && (
                                <Avatar size="small" marginRight={1} username={props.note?.username} />
                            )}
                            {props.note?.datetime && <DateText>{formatDate(props.note?.datetime)}</DateText>}
                        </>
                    ) : (
                        <SendButton disabled={!noteText || props.readOnly} sx={{ ml: 'auto' }} onClick={onSave} />
                    )}
                </Box>
            </Box>
        );
    },
)(
    ({ theme }) => `
    display: flex;
    flex-direction: column;
    width: 100%;
    height: ${theme.spacing(20)};
    padding: ${theme.spacing(2)};
    padding-bottom: 0;
    border-radius: ${theme.shape.borderRadius}px;
    background-color: ${theme.components?.Input?.backgroundColor};
    border: 1px solid ${theme.components?.Input?.borderColor};
`,
);

const ReportNote = styled(
    (
        props: BoxProps & {
            report: IReportNote;
        },
    ) => {
        const { report, ...rest } = props;
        const theme = useTheme();
        return (
            <Box {...rest} component="li">
                <Typography
                    variant="subtitle2"
                    sx={{ display: 'flex', gap: 0.5, mb: 1 }}
                    color={theme.palette.error.main}
                >
                    <ErrorOutlineIcon color="error" fontSize="small" />
                    {props.report.category}
                </Typography>
                <Input value={props.report.description} readOnly />
                {props.report?.username && (
                    <Box pb={2} display="flex" alignItems="center">
                        <Avatar size="small" username={props.report?.username} />
                    </Box>
                )}
            </Box>
        );
    },
)(
    ({ theme }) => `
    display: flex;
    flex-direction: column;
    width: 100%;
    height: ${theme.spacing(20)};
    padding: ${theme.spacing(2)};
    padding-bottom: 0;
    border-radius: ${theme.shape.borderRadius}px;
    background-color: ${theme.palette.background.paper};
    border: 1px solid ${theme.palette.error.main};
    `,
);

export const TicketNotes = memo(
    forwardRef((_props, ref: ForwardedRef<HTMLUListElement>) => {
        const user = useUser();
        const { notes, reportNote, saveNote, editNote } = useNotes();
        const [expNote, setExpNote] = useState<INote>();
        const [anchorEl, setAnchor] = useState<HTMLLIElement>();
        const onExpand = useCallback((li: HTMLLIElement, note: INote) => {
            setExpNote(note);
            setAnchor(li);
        }, []);
        const onSave = useCallback(
            async (text: string) => {
                if (expNote && notes.includes(expNote)) {
                    return editNote(text, notes.indexOf(expNote));
                }
                return saveNote(text);
            },
            [saveNote, editNote, expNote],
        );

        const editable = useMemo(() => {
            if (!expNote) return false;

            return !notes.includes(expNote) || (expNote.email && expNote.email === user?.email);
        }, [expNote, notes]);

        return (
            <Box height="60%" pb={5} pt={0} ref={ref}>
                <NoteExtendedPopper
                    note={expNote}
                    anchorEl={anchorEl}
                    disabled={!editable}
                    onSave={onSave}
                    onClose={() => setExpNote(undefined)}
                />
                <NotesList>
                    <Title sx={{ p: 0, mt: 2, mb: 3 }}>{`Notes (${notes.length})`}</Title>
                    <NoteInput key={notes.length} onExpand={onExpand} onSave={onSave} />
                    {reportNote && <ReportNote report={reportNote} sx={{ mb: 2 }} />}
                    {notes.map((note) => (
                        <NoteInput key={note.datetime} readOnly note={note} onExpand={onExpand} />
                    ))}
                </NotesList>
            </Box>
        );
    }),
);

TicketNotes.displayName = 'TicketNotes';

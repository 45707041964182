import { routes } from '@tymely/config';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

export type PolicyParams = {
    organizationId: string;
    intentId: string;
    versionId?: string;
};

type NavigateToPolicyArgs = {
    organizationId?: number | string;
    intentId?: number | string;
    versionId?: number | string;
};

const usePolicyParams = () => {
    const [queryParams] = useSearchParams();
    const { organizationId, intentId, versionId } = useParams<PolicyParams>();
    const _navigate = useNavigate();

    const navigate = (params: NavigateToPolicyArgs) => {
        if (!params.organizationId && !organizationId) {
            return;
        }

        const orgId = params.organizationId ?? organizationId;
        let destination = `${routes.graphPolicyEditor}/${orgId}`;

        if (params.intentId) {
            destination = `${destination}/${params.intentId}`;
        }

        if (params.versionId) {
            destination = `${destination}/${params.versionId}`;
        }

        _navigate(`${destination}?${queryParams}`);
    };

    return {
        organizationId: Number(organizationId),
        intentId: Number(intentId),
        versionId: Number(versionId),
        navigate,
    };
};

export default usePolicyParams;

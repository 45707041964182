import { Box, styled } from '@mui/material';

type ArgCardProps = {
    withApproval?: boolean;
    approved?: boolean;
    active?: boolean;
    disabled?: boolean;
};

export const ArgCard = styled(Box, {
    shouldForwardProp: (prop: string) => !['withApproval', 'approved', 'active', 'disabled'].includes(prop),
})<ArgCardProps>`
    width: 100%;
    border-radius: ${({ theme }) => theme.spacing(2)};
    padding: ${({ theme }) => theme.spacing(2)};
    ${({ withApproval, active, approved }) =>
        withApproval &&
        !active &&
        !approved &&
        "background-image: url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%238DFEC8' stroke-width='1' stroke-dasharray='4%2c 5' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e\");"};
    ${({ withApproval, approved, theme }) =>
        withApproval &&
        approved &&
        `
        background-color: ${theme.palette.success.light};
        border: none;
    `};
    ${({ disabled, approved }) =>
        disabled &&
        approved &&
        `
        background: #eee;
    `}
    ${({ withApproval, active, theme }) =>
        withApproval &&
        active &&
        `
        box-shadow: 0 0 0 1px ${theme.palette.text.primary};
    `};
`;

import { ReactNode, useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import { CONNECTION_STATE_CHANGE, ConnectionState, PubSub } from '@aws-amplify/pubsub';
import { Loader } from '@tymely/components';
import { useAuthenticate } from '@tymely/services';
import { api } from '@tymely/api';
import { Box } from '@mui/material';
import { fetchAuthSession } from '@aws-amplify/auth';
import { CookieStorage, Hub } from 'aws-amplify/utils';
import { cognitoUserPoolsTokenProvider } from '@aws-amplify/auth/cognito';
import { datadogLogs } from '@datadog/browser-logs';

const isLocalhost = window.location.hostname === 'localhost';

export const pubSub = new PubSub({
    region: 'us-east-1',
    endpoint: import.meta.env.VITE_PUBSUB_ENDPOINT,
});

Hub.listen('pubsub', (data: any) => {
    const { payload } = data;
    if (payload.event === CONNECTION_STATE_CHANGE) {
        const connectionState = payload.data.connectionState as ConnectionState;
        datadogLogs.logger.info(`PubSub connection state change: ${connectionState}`);
    }
});

document.addEventListener('visibilitychange', () => {
    datadogLogs.logger.info(`Page visibility change: ${document.visibilityState}`);
});

const AuthConfigure = () => {
    Amplify.configure({
        Auth: {
            Cognito: {
                userPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID,
                userPoolClientId: import.meta.env.VITE_COGNITO_CLIENT_ID,
                identityPoolId: import.meta.env.VITE_COGNITO_IDENTITY_POOL_ID,
            },
            // mandatorySignIn: true,
            // region: 'us-east-1',
        },
    });

    cognitoUserPoolsTokenProvider.setKeyValueStorage(
        new CookieStorage({
            domain: isLocalhost ? 'localhost' : '.tymelyinsights.com',
            secure: isLocalhost,
            expires: 3, // days
            sameSite: 'strict',
            path: '/',
        }),
    );

    fetchAuthSession().then((info) => {
        api.post('/auth/attach-policy', { identity_id: info.identityId });
    });
};

AuthConfigure();

export const AuthContext = ({ children }: { children: ReactNode }) => {
    const { authenticate, user: authUser } = useAuthenticate();
    useEffect(() => {
        authenticate();
    }, []);

    if (!authUser) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    height: '100vh',
                }}
            >
                <Loader />
            </Box>
        );
    }

    return <div id="app-context-children">{children}</div>;
};

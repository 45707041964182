import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { TemplateError, useApi } from '@tymely/api';
import { ITemplateVariable, IResponseTemplate } from '@tymely/atoms';

export const RESPONSE_TEMPLATE_QUERY_KEY = 'response-template';
export const RENDER_TEMPLATE_QUERY_KEY = 'render-template';
export const FREE_VARS_QUERY_KEY = 'free-vars';

const DEFAULT_RESPONSE_TEMPLATE: IResponseTemplate = {
    id: 0,
    response_txt: '',
    personalized: true,
    additional_data: {},
};

export const useFetchResponseQuery = (templateId?: number) => {
    const api = useApi();
    return useQuery<IResponseTemplate, TemplateError>({
        queryKey: [RESPONSE_TEMPLATE_QUERY_KEY, templateId],
        queryFn: () =>
            templateId ? api.get(`response-template/${templateId}`) : Promise.resolve(DEFAULT_RESPONSE_TEMPLATE),
        enabled: Boolean(templateId),
        initialData: DEFAULT_RESPONSE_TEMPLATE,
        meta: {
            errorMessage: `Failed to load template ${templateId}`,
        },
    });
};

export const useRenderTemplateQuery = (
    template: string,
    variables: Record<string, ITemplateVariable>,
    sanitize?: boolean,
    enabled = true,
) => {
    const api = useApi();
    return useQuery<string>({
        queryKey: [RENDER_TEMPLATE_QUERY_KEY, template, variables, sanitize],
        queryFn: () => api.post('template/render', { template, variables, sanitize }),
        enabled,
        retry: false,
    });
};

export const useTemplateFreeVariablesQuery = (template: string) => {
    const api = useApi();
    return useQuery<string[]>({
        queryKey: [FREE_VARS_QUERY_KEY, template],
        queryFn: () => api.post('template/free-variables', { template }),
        retry: false,
    });
};

export const EDIT_TEMPLATE_MUTATION_KEY = 'edit-response-template';
export const RESPONSE_TEMPLATE_MUTATION_KEY = 'response-template';

export const useEditResponseTemplateMutation = () => {
    const api = useApi();
    const queryClient = useQueryClient();
    return useMutation<
        IResponseTemplate,
        AxiosError | TemplateError,
        { template: IResponseTemplate; isPromptMode: boolean }
    >({
        mutationKey: [EDIT_TEMPLATE_MUTATION_KEY],
        mutationFn: ({ template, isPromptMode }) =>
            api.put(`response-template/${template.id}`, {
                ...template,
                additional_data: {
                    prompt_mode: isPromptMode,
                    prompt_template: isPromptMode
                        ? template.response_txt
                        : template.additional_data?.prompt_template || '',
                    standard_template: !isPromptMode
                        ? template.response_txt
                        : template.additional_data?.standard_template || '',
                },
            }),
        onSuccess(data) {
            queryClient.setQueryData([RESPONSE_TEMPLATE_MUTATION_KEY, data.id], () => data);
        },
    });
};

export const useCreateResponseTemplateMutation = () => {
    const api = useApi();
    const queryClient = useQueryClient();
    return useMutation<IResponseTemplate, AxiosError, Omit<IResponseTemplate, 'id'>>({
        mutationFn: (template) => api.post('response-template', template),
        onSuccess: (data) => {
            queryClient.setQueryData([RESPONSE_TEMPLATE_MUTATION_KEY, data.id], () => data);
        },
    });
};

export const PROCESS_TEMPLATE_MUTATION_KEY = 'process-template';

export const useProcessTemplateMutation = () => {
    const api = useApi();
    return useMutation<string, AxiosError, { response_txt: string; comment_id: number }>({
        mutationKey: [PROCESS_TEMPLATE_MUTATION_KEY],
        mutationFn: (data) =>
            api.post(
                `process_template_endpoint?response_txt=${encodeURIComponent(data.response_txt)}&comment_id=${data.comment_id}`,
                data,
            ),
    });
};

import React, { FC, HTMLProps } from 'react';
import { Box, useTheme } from '@mui/material';

const escapeRegExp = (text: string) => {
    return text.replace(/[^\w\s]/g, '\\$&');
};

interface RenderHighlightedOptionProps {
    option: { label: string } | string;
    inputValue: string;
    sx?: React.CSSProperties;
    props: HTMLProps<any>;
}

const RenderHighlightedOption: FC<RenderHighlightedOptionProps> = ({ props, sx, option, inputValue }) => {
    const theme = useTheme();

    const _option = typeof option === 'string' ? option : option.label;

    const filterTerm = inputValue || '';
    const escapedFilterTerm = escapeRegExp(filterTerm);
    const parts = filterTerm ? _option.split(new RegExp(`(${escapedFilterTerm})`, 'gi')) : [_option];

    return (
        <Box {...props} sx={sx}>
            {parts.map((part, index) =>
                part && part.toLowerCase() === filterTerm.toLowerCase() ? (
                    <Box display={'inline'} key={index} style={theme.customStyles?.highlightText}>
                        {part}
                    </Box>
                ) : (
                    <Box display={'inline'} key={index}>
                        {part}
                    </Box>
                ),
            )}
        </Box>
    );
};

export default RenderHighlightedOption;

import React from 'react';
import {
    IArgument,
    IArgumentCategory,
    IArgumentDisplayRegulator,
    IArgumentString,
    IArgumentUpdate,
    IDineshTicketOperations,
    requiresInput,
    SpecialValue,
    wereArgsChanged,
} from '@tymely/atoms';
import { Box, Button, FormHelperText, ListItem, styled } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import ApprovedIcon from '@tymely/components/Icons/Approved';
import { useApproveArgumentMutation, useCreateTicketCrumb, useSelectedComment, useTicket } from '@tymely/services';

import { ArgErrorFallback, Argument } from '../ArgumentTypes/Layout';
import { ArgCard } from './styled';
import ArgEditor, { ArgEditorProps } from './ArgEditor';
import { useArgumentsTabsContext } from './ArgumentsTabsProvider';

const MEDIA_ARGUMENT_DTYPES: IArgument['dtype'][] = [
    'Image',
    'Url',
    'VideoUrl',
    'list[Image]',
    'list[Url]',
    'list[VideoUrl]',
];

const _ListItem = styled(ListItem)`
    padding-left: 0;
    padding-right: 0;
`;

const _ApprovedIconContainer = styled(Box)`
    width: 100%;
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(1)};
`;

const _ApproveContainer = styled(Box)`
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
`;

const ArgumentSeparator = styled('hr')`
    margin: ${({ theme }) => theme.spacing(0.5, -4)};
    border-top: ${({ theme }) => `1px solid ${theme.palette.divider}`};
`;

type ArgumentListItemProps = {
    argument: IArgument;
    groupArgument?: IArgumentCategory;
    displayRegulatorArgument?: IArgumentDisplayRegulator;
    searchArgument?: IArgumentString;
    withCopy?: boolean;
    withApproval?: boolean;
    approved?: boolean;
    editable?: boolean;
    loading?: boolean;
    withSeparator?: boolean;
    onUpdate?: (updates: IArgumentUpdate[]) => void;
    setHighlightText?: (text: string) => void;
};

const ArgumentListItem = React.memo(
    ({
        argument,
        groupArgument,
        displayRegulatorArgument,
        searchArgument,
        withCopy,
        withApproval,
        approved,
        editable,
        loading,
        withSeparator,
        onUpdate,
    }: ArgumentListItemProps) => {
        const { version, onHighlightText } = useArgumentsTabsContext();
        const comment = useSelectedComment();
        const [expanded, setExpanded] = React.useState(false);
        const [intermediateArgument, setIntermediateArgument] = React.useState<IArgument>();

        React.useEffect(() => {
            setExpanded(Boolean(!approved && editable));
        }, [approved, editable]);

        const editorProps: ArgEditorProps = {
            argument,
            groupArgument,
            displayRegulatorArgument,
            searchArgument,
            loading,
            disabled: !editable,
            setHighlightText: onHighlightText,
            onChange: async (args, intermediate) => {
                if (intermediate) {
                    setIntermediateArgument(args.find((arg) => arg.id === argument.id));
                } else if (wereArgsChanged([argument], args)) {
                    const updates = args.map((arg) => ({
                        id: arg.id,
                        value: arg.value,
                        special_value: (arg.value === null && arg.special_value) || undefined,
                    }));
                    onUpdate?.(updates);
                    setExpanded(false);
                }
                return args;
            },
        };

        const isApproveEnabled = !requiresInput(intermediateArgument || argument);
        const approveMutation = useApproveArgumentMutation(comment!.id);
        const createTicketCrumb = useCreateTicketCrumb();
        const ticket = useTicket();
        const selectedComment = useSelectedComment();

        const [approveInProgress, setApproveInProgress] = React.useState(false);
        const onApprove = React.useCallback(async () => {
            if (!argument.approved_at && !intermediateArgument) {
                try {
                    setApproveInProgress(true);
                    createTicketCrumb(IDineshTicketOperations.USER_APPROVED_ARGUMENT, {
                        ticket_id: ticket?.id ?? null,
                        argument_id: argument.id,
                        arg_name: argument.name,
                        argument_value: argument.special_value || argument.value,
                        argument_prev_value: argument.value,
                        comment_id: selectedComment?.id ?? null,
                        ticket_intent: selectedComment?.selected_intent_id ?? null,
                    });
                    await Promise.all([
                        approveMutation.mutateAsync(argument),
                        groupArgument && approveMutation.mutateAsync(groupArgument),
                    ]);
                    onUpdate?.([
                        {
                            id: argument.id,
                            value: argument.value,
                            special_value: argument.special_value ?? undefined,
                            isApproval: true,
                        },
                    ]);
                } finally {
                    setApproveInProgress(false);
                }
            }
            setExpanded(false);
        }, [onUpdate, argument, groupArgument]);

        React.useLayoutEffect(() => {
            if (['string', 'number'].includes(typeof argument.value)) {
                onHighlightText?.(expanded ? String(argument.value) : '');
            }
        }, [expanded, argument.value, onHighlightText]);

        const isDTypeValid = MEDIA_ARGUMENT_DTYPES.includes(argument.dtype);

        const setSpecialValue = React.useCallback(
            (id: number, specialValue?: Exclude<SpecialValue, 'none'>) => {
                if (version) {
                    return;
                }
                const updates: IArgumentUpdate[] = [];
                if (groupArgument) {
                    updates.push({
                        id: groupArgument.id,
                        value: null,
                        special_value: specialValue,
                    });
                }
                updates.push({
                    id,
                    value: null,
                    special_value: specialValue,
                });
                onUpdate?.(updates);
                setExpanded(false);
            },
            [version, withApproval, expanded, onUpdate, groupArgument],
        );

        const onSetUnspecified = (id: number) => setSpecialValue(id, 'unspecified');
        const onSetUnknown = (id: number) => setSpecialValue(id, 'unknown');

        const _onClick = () => {
            if (approved && editable && !expanded) {
                setExpanded(true);
            }
        };

        return (
            <>
                {withSeparator && <ArgumentSeparator />}
                <_ListItem onClick={_onClick} data-argument-name={argument.name}>
                    <ArgCard
                        withApproval={withApproval}
                        active={editable && !approved}
                        approved={approved}
                        disabled={!editable}
                    >
                        <Box display="flex" flexDirection="row">
                            {isDTypeValid && (
                                <ErrorBoundary FallbackComponent={ArgErrorFallback}>
                                    <ArgEditor {...editorProps} />
                                </ErrorBoundary>
                            )}
                            {!isDTypeValid && (
                                <Argument
                                    argument={argument}
                                    className={argument.extractor_cls_name.toLowerCase()}
                                    withCopy={withCopy}
                                    disabled={loading || argument.arg_type === 'INFO_ARGUMENT' || argument.is_stale}
                                    mutable={!version}
                                    onSetUnspecified={onSetUnspecified}
                                    onSetUnknown={onSetUnknown}
                                    onTitleClick={() => setExpanded(!expanded)}
                                >
                                    <_ApprovedIconContainer>
                                        <ArgEditor {...editorProps} />
                                        {withApproval && !expanded && approved && <ApprovedIcon />}
                                    </_ApprovedIconContainer>
                                </Argument>
                            )}
                        </Box>
                        {expanded && withApproval && isApproveEnabled && (
                            <_ApproveContainer>
                                {((withApproval && expanded) || argument.show_description) && argument.description && (
                                    <FormHelperText sx={{ mr: 1, flex: 1, fontStyle: 'italic', ml: 2 }}>
                                        {argument.description}
                                    </FormHelperText>
                                )}
                                <Button
                                    disabled={approveInProgress}
                                    color="primary"
                                    variant="contained"
                                    onClick={onApprove}
                                >
                                    {approved || intermediateArgument ? 'Done' : 'Approve'}
                                </Button>
                            </_ApproveContainer>
                        )}
                    </ArgCard>
                </_ListItem>
            </>
        );
    },
);

ArgumentListItem.displayName = 'ArgumentListItem';

export default ArgumentListItem;

import { Box, ButtonBase, IconButton, Link, styled, Typography, TypographyProps } from '@mui/material';
import React, { ReactNode, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import { ICrumbAdditionalData, ITicketTrailEvent } from '@tymely/atoms';
import { EditAttributes, Error, ExpandLess, ExpandMore, Person, Rule, Search, Comment } from '@mui/icons-material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { LlmTrailEvent } from '@tymely/atoms/llmTrail.atoms';
import JsonView from '@tymely/components/JsonView';
import AgentBox, { _Expanded, _JsonContainer } from '@tymely/features/Ticket/TicketLayout/Conversation/AgentBox';

import { formatBubbleDate, isTicketTrailEvent } from './utils';

const IconTag = (props: { text: string; icon: ReactNode; title?: string }) => (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.25 }} title={props.title}>
        <Box sx={{ height: 14, overflow: 'visible', display: 'flex', alignItems: 'center' }}>{props.icon}</Box>
        <span>{props.text}</span>
    </Box>
);

function getEventTags(event: ITicketTrailEvent): ReactNode[] {
    const {
        comment_id,
        additional_data: { username, policy_type, lb_type, logic_box_id, argument_name, arg_name, search_term },
    } = event;
    const _arg_name = arg_name || argument_name;
    return [
        username ? <IconTag key="username" text={username} icon={<Person sx={{ fontSize: 15 }} />} /> : false,
        comment_id ? (
            <ButtonBase centerRipple sx={{ p: 0 }}>
                <CopyToClipboard text={String(comment_id)}>
                    <span>
                        <IconTag text={String(comment_id)} icon={<Comment sx={{ fontSize: 15 }} />} />
                    </span>
                </CopyToClipboard>
            </ButtonBase>
        ) : (
            false
        ),
        policy_type ? `${policy_type}` : false,
        lb_type ? `${lb_type}` : false,
        logic_box_id ? `${logic_box_id}` : false,
        _arg_name ? (
            <IconTag text={_arg_name} title="Argument name" icon={<EditAttributes sx={{ fontSize: 25 }} />} />
        ) : (
            false
        ),
        search_term ? (
            <IconTag text={search_term} title="Search term" icon={<Search sx={{ fontSize: 15 }} />} />
        ) : (
            false
        ),
    ].filter(Boolean);
}

const EVENT_DETAILS: ((data: ICrumbAdditionalData) => ReactNode)[] = [
    ({ decision }) =>
        decision?.title ? (
            <IconTag text={decision.title} title="Decision" icon={<Rule sx={{ fontSize: 15 }} />} />
        ) : (
            false
        ),
];

const _Root = styled(Typography)`
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #ddd;
`;

const _Header = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(1)};
`;

const _Label = styled('strong', { shouldForwardProp: (prop: string) => !['isEmptyData'].includes(prop) })<{
    isEmptyData: boolean;
}>`
    ${({ isEmptyData }) => !isEmptyData && 'cursor: pointer;'};
`;

const _Error = styled(Error)`
    display: 'inline';
    font-size: 16;
`;

const _IconButton = styled(IconButton, { shouldForwardProp: (prop: string) => !['isEmptyData'].includes(prop) })<{
    isEmptyData: boolean;
}>`
    visibility: ${({ isEmptyData }) => (isEmptyData ? 'hidden' : 'visible')};
`;

const _Filler = styled(Box)`
    flex: 1;
`;

type EventItemProps = TypographyProps & {
    event: ITicketTrailEvent | LlmTrailEvent;
};

const EventItem = ({ event, sx }: EventItemProps) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const _isTicketTrailEvent = isTicketTrailEvent(event);
    const eventData = _isTicketTrailEvent
        ? event.additional_data
        : {
              raw_response: event.raw_response,
              prompt: event.prompt,
              request: event.request,
              response: event.response,
          };
    const isEmptyData = useMemo(() => isEmpty(eventData), [eventData]);
    const isBigData = useMemo(() => JSON.stringify(eventData).length > 1000, [eventData]);
    const title = _isTicketTrailEvent ? event.operation : event?.request?.template_name;
    const details = useMemo(() => EVENT_DETAILS.map((item) => item(eventData)).filter(Boolean), [eventData]);

    return (
        <_Root variant="caption" color="GrayText" sx={sx}>
            {!_isTicketTrailEvent && title.startsWith('AGENT_') && <AgentBox event={event as LlmTrailEvent} />}
            <_Header>
                {_isTicketTrailEvent && event.status !== 'SUCCESS' && <_Error color="warning" />}
                <_Label onClick={() => !isEmptyData && setIsExpanded((val) => !val)} isEmptyData={isEmptyData}>
                    {title}
                </_Label>
                {_isTicketTrailEvent && (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>{getEventTags(event)}</Box>
                )}
                {!_isTicketTrailEvent && (
                    <Link
                        component="button"
                        onClick={() => {
                            const url = `prompt-editor?prompt=${event.prompt_template.name}&version=${event.prompt_template.id}`;
                            window.open(url, '_blank', 'noopener,noreferrer');
                        }}
                        underline="hover"
                        color="primary"
                        sx={{ fontSize: 14, fontWeight: 500 }}
                    >
                        Open Prompt Editor
                    </Link>
                )}
                <_Filler />
                <Box>{formatBubbleDate(`${event.created_date}Z`, false, true)}</Box>
                <_IconButton isEmptyData={isEmptyData} onClick={() => !isEmptyData && setIsExpanded((val) => !val)}>
                    {isExpanded ? <ExpandLess /> : <ExpandMore />}
                </_IconButton>
            </_Header>
            {isExpanded ? (
                <_Expanded>
                    <_JsonContainer>
                        <JsonView data={eventData} shouldExpandNode={() => !isBigData} />
                    </_JsonContainer>
                </_Expanded>
            ) : (
                !isEmpty(details) && (
                    <_Expanded>
                        {details.map((item, i) => (
                            <Box key={i}>{item}</Box>
                        ))}
                    </_Expanded>
                )
            )}
        </_Root>
    );
};

export default EventItem;

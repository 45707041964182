import flatten from 'lodash/flatten';
import React, { useEffect } from 'react';
import {
    useAppMode,
    useConversation,
    useEventSubscription,
    useSelectedComment,
    useSetTicket,
    useTicket,
    useTicketEventsQuery,
} from '@tymely/services';
import { pdfjs } from 'react-pdf';
import { useApi } from '@tymely/api';
import { ITicket } from '@tymely/atoms';
import { useLlmTrailEventsQuery } from '@tymely/services/hooks/llmTrail.services';

import { Response } from '../Response';
import { BottomScrollButton, UpScrollButton } from './ScrollButton';
import Discussion from './Discussion';
import { ConversationContainer } from './styled';

import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString();

type ConversationProps = {
    highlights?: [number, number][];
    highlightText?: string;
    canSave?: boolean;
    canReview?: boolean;
    canSubmit?: boolean;
    eventMode: 'ticketEventsMode' | 'llmMode' | null;
};

const useEventData = (ticketId: number, eventMode: 'ticketEventsMode' | 'llmMode' | null) => {
    const {
        data: ticketEventsData,
        isLoading: isLoadingTicketEvents,
        isFetchingNextPage: isFetchingNextPageTicketEvents,
        fetchNextPage: fetchNextPageTicketEvents,
        hasNextPage: hasNextPageTicketEvents,
    } = useTicketEventsQuery(ticketId, eventMode === 'ticketEventsMode');

    const {
        data: llmEventsData,
        isLoading: isLoadingLlmEvents,
        isFetchingNextPage: isFetchingNextPageLlmEvents,
        fetchNextPage: fetchNextPageLlmEvents,
        hasNextPage: hasNextPageLlmEvents,
    } = useLlmTrailEventsQuery(ticketId, eventMode === 'llmMode');

    const isLoading = isLoadingTicketEvents || isLoadingLlmEvents;
    const isFetchingNextPage = isFetchingNextPageTicketEvents || isFetchingNextPageLlmEvents;
    const hasNextPage = hasNextPageTicketEvents || hasNextPageLlmEvents;

    const fetchNextPage = () => {
        if (eventMode === 'ticketEventsMode' && hasNextPageTicketEvents) {
            fetchNextPageTicketEvents();
        } else if (eventMode === 'llmMode' && hasNextPageLlmEvents) {
            fetchNextPageLlmEvents();
        }
    };

    const events = React.useMemo(() => {
        if (eventMode === 'ticketEventsMode') {
            return flatten(ticketEventsData?.pages);
        }
        if (eventMode === 'llmMode') {
            return flatten(llmEventsData?.pages);
        }
        return [];
    }, [eventMode, ticketEventsData, llmEventsData]);

    return {
        events,
        isLoading,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
    };
};

const Conversation = React.memo(({ highlightText, eventMode, ...rest }: ConversationProps) => {
    const chatRef = React.useRef<{
        scrollToTop: () => void;
        scrollToBottom: () => void;
    }>(null);

    const { isOnline } = useAppMode();
    const ticket = useTicket();
    const { events, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } = useEventData(ticket.id, eventMode);

    useEffect(() => {
        if (hasNextPage) {
            fetchNextPage();
        }
    }, [events, hasNextPage]);

    const [isScrolledToTop, setIsScrolledToTop] = React.useState(true);
    const [isScrolledToBottom, setIsScrolledToBottom] = React.useState(true);
    const api = useApi();
    const setTicket = useSetTicket();

    const { comments, loaded, refetch, setCommentIdToFocusOn } = useConversation();

    useEventSubscription({ channel: `ticket/${ticket.id}`, objectTypes: ['Comment', 'Ticket'] }, async (event) => {
        if (event.object_type === 'Ticket') {
            const ticketUpdated = (await api.get(`ticket/${ticket.id}`)) as ITicket;
            setTicket(ticketUpdated);
        } else if (event.object_type === 'Comment' && event.action === 'created') {
            await refetch();
            chatRef.current?.scrollToBottom();
        }
    });

    const selectedComment = useSelectedComment();

    return (
        <ConversationContainer id="conversation">
            <UpScrollButton disabled={isScrolledToTop} onClick={chatRef.current?.scrollToTop} />
            <Discussion
                highlightText={highlightText}
                ref={chatRef}
                ticket={ticket}
                comments={comments}
                commentIdToFocusOn={selectedComment?.id}
                selectedCommentId={selectedComment?.id}
                loading={!loaded}
                onSelectComment={!isOnline ? setCommentIdToFocusOn : undefined}
                onDeselectComment={() => setCommentIdToFocusOn(undefined)}
                events={{
                    isLoading: isLoading || isFetchingNextPage,
                    data: events || undefined,
                }}
                onScrollToTop={setIsScrolledToTop}
                onScrollToBottom={setIsScrolledToBottom}
            />
            <BottomScrollButton disabled={isScrolledToBottom} onClick={chatRef.current?.scrollToBottom} />
            <Response
                {...rest}
                onResize={() => {
                    if (isScrolledToBottom) {
                        setTimeout(() => chatRef.current?.scrollToBottom(), 200); // wait for resize animation & relayout
                    }
                }}
            />
        </ConversationContainer>
    );
});

Conversation.displayName = 'Conversation';

export default Conversation;

import findLast from 'lodash/findLast';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useMemo } from 'react';
import { selector, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { datadogLogs } from '@datadog/browser-logs';
import { AxiosError } from 'axios';
import { Nullable } from '@global/types';
import { useApi } from '@tymely/api';
import {
    commentIdToFocusOnAtom,
    historicAnalysis,
    IComment,
    IDecision,
    IDineshTicketOperations,
    IPolicySet,
    isPolicyEvaluated,
    isPolicyEvaluationRunning,
    submittingComments,
    ticketAtom,
    TicketReportCategory,
} from '@tymely/atoms';
import { Cluster, BatchClusteringParams } from '@tymely/services/types/clustering';
import usePolicyParams from '@tymely/pages/GraphPolicyEditor/hooks/usePolicyParams';

import { useIntentsQuery } from './intent.services';
import { useSetAlert } from './alerts.services';
import { useIsTicketLocked, useTicket } from './ticket.services';
import { useNotes, useTicketReport } from './note.services';
import { AppMode, useAppMode } from './mode';
import { useCreateHandlingDurationsCrumb } from './ticketTrail.services';
import { useArgumentsQuery } from './argument.services';
import { useFeatureFlags } from './feature.services';
import { useActionsMetadataQuery } from './action.services';
import { useFetchWfPolicyIsImplemented } from './organization.services';

export const DECISION_QUERY_KEY = 'decision';
export const ARGUMENTS_QUERY_KEY = 'arguments';
export const AGENT_RESPONSE_QUERY_KEY = 'agentResponse';
export const RESET_COMMENT_MUTATION_KEY = 'resetComment';

const useUpdateCommentMutation = () => {
    const api = useApi();
    const updateCommentCached = _useUpdateCommentCached();
    return useMutation({
        mutationFn: (comment: IComment) => api.put<IComment>(`comment/${comment.id}`, comment),
        onMutate(comment) {
            updateCommentCached(comment);
        },
    });
};

export const _useUpdateCommentCached = () => {
    const setTicket = useSetRecoilState(ticketAtom);

    return useCallback(
        (comment: IComment) => {
            setTicket(
                (ticket) =>
                    ticket && {
                        ...ticket,
                        comments: ticket.comments.map((it) => (it.id === comment.id ? comment : it)),
                    },
            );
        },
        [setTicket],
    );
};

export const useCommentApproveIntent = () => {
    const updateCommentMutation = useUpdateCommentMutation();
    return (comment: IComment, approvedBy: IComment['agent_username']) => {
        const commentUpdate = {
            ...comment,
            additional_data: {
                ...comment.additional_data,
                is_intent_approved: true,
            },
        };

        if (approvedBy) {
            commentUpdate.agent_username = approvedBy;
        }

        return updateCommentMutation.mutateAsync(commentUpdate);
    };
};

export const useCommentToggleIsCustomer = () => {
    const updateCommentMutation = useUpdateCommentMutation();
    return (comment: IComment) =>
        updateCommentMutation.mutateAsync({
            ...comment,
            is_customer: !comment.is_customer,
        });
};

export const useCommentIdToFocusOn = () => useRecoilValue(commentIdToFocusOnAtom);

export const useSetCommentIdToFocusOn = () => {
    const setCommentIdToFocusOnAtom = useSetRecoilState(commentIdToFocusOnAtom);
    return useCallback((id?: number) => setCommentIdToFocusOnAtom(id), [setCommentIdToFocusOnAtom]);
};

export const useSelectedComment = () => useRecoilValue(selectedCommentSelector);

export const useHistoricAnalysis = () => useRecoilValue(historicAnalysis);
export const useSetHistoricAnalysis = () => {
    const seHistoricAnalysis = useSetRecoilState(historicAnalysis);
    return useCallback((historicAnalysis: IComment) => seHistoricAnalysis(historicAnalysis), [seHistoricAnalysis]);
};

export const useFindIntent = (intentId: Nullable<IPolicySet['id']> | undefined) => {
    const intents = useIntentsQuery();
    return intents.data?.find((intent) => intent.id === intentId);
};

export const useSelectedCommentIntents = () => {
    const intents = useIntentsQuery();
    const selectedComment = useRecoilValue(selectedCommentSelector);

    const intentIds = useMemo(() => {
        return new Set(
            [selectedComment?.selected_intent_id]
                .concat(selectedComment?.additional_data.multiple_intents)
                .filter(Boolean),
        );
    }, [selectedComment?.selected_intent_id, selectedComment?.additional_data.multiple_intents]);

    return useMemo(
        () =>
            Array.from(intentIds)
                .map((id) => intents.data?.find((intent) => intent.id === id))
                .filter(Boolean),
        [intents.data, intentIds],
    );
};

export const selectedCommentSelector = selector<Nullable<IComment>>({
    key: 'selectedCommentSelector',
    get: ({ get }) => {
        const comments = get(ticketAtom)?.comments;
        const commentIdToFocusOn = get(commentIdToFocusOnAtom);
        let comment = comments?.find(({ id, is_customer }) => id === commentIdToFocusOn && is_customer);
        if (!comment) {
            comment = findLast(comments, (comment) => comment.is_customer);
        }
        return comment ?? null;
    },
});

export const SPECIAL_WORDS = ['none', 'null', 'undefined', 'unspecified', 'neither', 'unknown'];

export const highlightSpecialWords = (text: string, specialWords: string[] = SPECIAL_WORDS) => {
    return specialWords.reduce((text, word) => text.replace(new RegExp(`(${word})`, 'gi'), '<mark>$1</mark>'), text);
};

export const useAgentResponse = (lodable?: boolean) => {
    const api = useApi();
    const ticket = useTicket();
    const selectedComment = useSelectedComment();
    const commentId = selectedComment?.id;
    const updateCommentCached = _useUpdateCommentCached();
    const { appMode = AppMode.Training } = useAppMode();
    const { saveNote } = useNotes();
    const queryClient = useQueryClient();
    const { data: decision } = useSelectedCommentDecisionQuery();
    const createHandlingDurationsCrumb = useCreateHandlingDurationsCrumb();
    const [submitting] = useRecoilState<{ [key: IComment['id']]: boolean }>(submittingComments);
    const updateResponse = useCallback(
        (response: string) => {
            queryClient.setQueryData<string>([AGENT_RESPONSE_QUERY_KEY, commentId], response);
        },
        [commentId],
    );
    const { report } = useTicketReport();

    const query = useQuery({
        queryKey: [AGENT_RESPONSE_QUERY_KEY, commentId],
        queryFn: () =>
            commentId
                ? (api.get(`comment/${commentId}`) as Promise<IComment>).then((comment) => {
                      updateCommentCached(comment);
                      return comment.response_body;
                  })
                : Promise.reject('Comment is not selected'),

        enabled: Boolean(selectedComment && lodable),
        initialData: selectedComment?.response_body,
        staleTime: Infinity,
    });

    const submitCommentMutation = useSubmitCommentMutation();
    const sendResponse = useCallback(
        async (response: string) => {
            if (!selectedComment) return;
            createHandlingDurationsCrumb(IDineshTicketOperations.USER_SUBMITTED_TICKET);
            return submitCommentMutation.mutateAsync({
                commentId: selectedComment.id,
                appMode,
                responseBody: response,
            });
        },
        [ticket?.id, appMode, selectedComment],
    );

    const updateCommentMutation = useUpdateCommentMutation();

    const addEscalation = useCallback(
        async (note: string, response: string, category: TicketReportCategory) => {
            if (!selectedComment) return;
            await updateCommentMutation.mutateAsync({ ...selectedComment, response_body: response });
            await report(category, note, true);
        },
        [saveNote],
    );

    return {
        ...query,
        appMode,
        agentResponse: decision ? (decision.workflow_id ? query.data : '') : null,
        notSet: !decision?.workflow_id || !selectedComment?.response_body,
        sendResponse,
        updateResponse,
        addEscalation,
        isSubmitting: !isEmpty(submitting),
    };
};

export const useDecisionQuery = (commentId?: IComment['id'], policySetId?: number, enabled = true) => {
    const api = useApi();
    const actionsMetadataQuery = useActionsMetadataQuery();

    return useQuery({
        queryKey: [DECISION_QUERY_KEY, commentId],
        queryFn: async () => {
            if (!commentId) {
                throw Error();
            }
            const decision = (await api.get(`comment/${commentId}/decision`, {
                params: {
                    policy_set_id: policySetId ?? undefined,
                },
            })) as Nullable<IDecision>;
            if (decision) {
                const action_executions = decision.action_executions?.map((action_exec) => ({
                    ...action_exec,
                    action_metadata: actionsMetadataQuery.data!.filter(
                        (md) => md.id === action_exec.action_metadata_id,
                    )[0],
                }));
                return { ...decision, action_executions };
            }
            return decision;
        },
        enabled: Boolean(enabled && commentId && actionsMetadataQuery.data),
        retry: false,
        staleTime: Infinity,
    });
};

export const useSelectedCommentDecisionQuery = (enabled = true) => {
    const selectedComment = useSelectedComment();
    const commentId = selectedComment?.id;
    const queryClient = useQueryClient();
    const decisionQuery = useDecisionQuery(
        commentId,
        selectedComment?.selected_intent_id ?? undefined,
        enabled && !!selectedComment?.selected_intent_id,
    );
    const updateDecision = (decision: IDecision | null) => {
        queryClient.setQueryData([DECISION_QUERY_KEY, commentId], decision);
    };

    return { ...decisionQuery, updateDecision };
};

export const EVALUATE_POLICY_MUTATION_KEY = 'evaluatePolicy';

export const useEvaluatePolicy = (opts?: { onSuccess?: () => void; onError: (error: AxiosError) => void }) => {
    const api = useApi();
    const setPolicyEvaluated = useSetRecoilState(isPolicyEvaluated);
    const setPolicyEvaluationRunning = useSetRecoilState(isPolicyEvaluationRunning);
    useFeatureFlags();
    const ticket = useTicket();
    const isTicketLockedQuery = useIsTicketLocked(ticket.id);

    return useMutation<IComment, AxiosError, { commentId: number; runAsync: boolean }>({
        mutationKey: [EVALUATE_POLICY_MUTATION_KEY],
        mutationFn: (params) => {
            setPolicyEvaluated(false);
            setPolicyEvaluationRunning(true);
            return api.post(`comment/${params.commentId}/evaluate-policy`, undefined, { params: { async: true } });
        },

        ...opts,
        onSuccess: () => {
            opts?.onSuccess?.();
            setPolicyEvaluated(true);
            isTicketLockedQuery.refetch().then(({ data: isLocked }) => {
                if (isLocked) {
                    setPolicyEvaluationRunning(false);
                }
            });
        },
        onError() {
            setPolicyEvaluationRunning(false);
        },
    });
};

const SUBMIT_COMMENT_MUTATION = 'submitComment';

export const useSubmitCommentMutation = () => {
    const api = useApi();
    return useMutation<
        IComment,
        AxiosError,
        {
            commentId: IComment['id'];
            dryMode?: boolean;
            appMode?: AppMode;
            responseBody?: Nullable<string>;
            afterHandling?: boolean;
        }
    >({
        mutationKey: [SUBMIT_COMMENT_MUTATION],
        mutationFn: ({ commentId, responseBody, dryMode = false, appMode, afterHandling = true }) =>
            api.post(
                `comment/${commentId}/submit`,
                {
                    response_body: responseBody,
                },
                {
                    params: {
                        dry_mode: dryMode,
                        mode: appMode,
                        after_handling: afterHandling,
                    },
                },
            ),
    });
};

export const useTagComment = () => {
    const setAlert = useSetAlert();
    const api = useApi();
    const ticket = useTicket();
    const updateCommentCached = _useUpdateCommentCached();
    const queryClient = useQueryClient();
    const { appMode = AppMode.Training } = useAppMode();
    const { mutate: evaluatePolicy } = useEvaluatePolicy();

    const comment = useSelectedComment();
    useArgumentsQuery({
        commentId: comment?.id,
        enabled: !!comment?.selected_intent_id,
    });

    useFeatureFlags();

    const createHandlingDurationsCrumb = useCreateHandlingDurationsCrumb();
    const fetchWfPolicyIsImplemented = useFetchWfPolicyIsImplemented();

    return useCallback(
        async (commentId: number, intentIds?: number[]) => {
            try {
                let comment;
                if (intentIds) {
                    comment = (await api.post(`comment/${commentId}/tag`, undefined, {
                        params: {
                            mode: appMode,
                            policy_set_id: intentIds,
                        },
                    })) as IComment;
                    evaluatePolicy({ commentId, runAsync: true });
                    fetchWfPolicyIsImplemented(ticket.organization.org_policy_set_id, intentIds[0]).then(
                        (isImplemented) => {
                            if (!isImplemented) {
                                createHandlingDurationsCrumb(IDineshTicketOperations.USER_TAGGED_UNSUPPORTED_INTENT);
                            }
                        },
                    );
                } else {
                    comment = (await api.post(`comment/${commentId}/untag`)) as IComment;
                }

                updateCommentCached(comment);

                await Promise.all([
                    queryClient.invalidateQueries({ queryKey: [DECISION_QUERY_KEY, commentId] }),
                    queryClient.invalidateQueries({ queryKey: [ARGUMENTS_QUERY_KEY, commentId] }),
                    queryClient.invalidateQueries({ queryKey: [AGENT_RESPONSE_QUERY_KEY, commentId] }),
                ]);
            } catch (error) {
                if (intentIds) {
                    datadogLogs.logger.error(`Failed tagging ${intentIds.join(', ')}`);
                    setAlert((error as Error).message, 'error', 5000, 'Tagging has failed');
                }
            }
        },
        [appMode, queryClient, updateCommentCached],
    );
};

export const useResetComment = (comment?: IComment) => {
    const updateCommentCached = _useUpdateCommentCached();
    const api = useApi();
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: [RESET_COMMENT_MUTATION_KEY, comment?.id],
        mutationFn: async () => {
            if (!comment) return;
            await api.put(`comment/${comment.id}/reset`);
            updateCommentCached({ ...comment, selected_intent_id: null });
            return comment;
        },
        onSuccess(comment) {
            if (!comment) return;
            return Promise.all([
                queryClient.invalidateQueries({ queryKey: [DECISION_QUERY_KEY, comment.id] }),
                queryClient.invalidateQueries({ queryKey: [ARGUMENTS_QUERY_KEY, comment.id] }),
                queryClient.invalidateQueries({ queryKey: [AGENT_RESPONSE_QUERY_KEY, comment.id] }),
            ]);
        },
    });
};

export const useGetBatchDecisionClustering = () => {
    const api = useApi();
    const { organizationId, intentId } = usePolicyParams();

    return useMutation({
        mutationFn: async ({
            clusteringRequests,
            knownClusters,
            llm_model_id,
            clustering_prompt_override,
        }: BatchClusteringParams) => {
            return api.post<Cluster[]>(
                '/comments/clustering/batch',
                {
                    clustering_requests: clusteringRequests,
                    known_clusters: knownClusters,
                },
                {
                    params: {
                        organization_id: organizationId,
                        intent_id: intentId,
                        run_async: true,
                        llm_model_id,
                        clustering_prompt_override,
                    },
                },
            );
        },
    });
};

import { Box, CircularProgress, styled, Typography } from '@mui/material';
import { IComment, ITicket, ITicketTrailEvent } from '@tymely/atoms';
import React from 'react';
import { LlmTrailEvent } from '@tymely/atoms/llmTrail.atoms';

import EventItem from './EventItem';
import { isComment } from './utils';
import Comment from './Comment';

type CommentEvents = { isLoading: boolean; data?: ITicketTrailEvent[] | LlmTrailEvent[] };

export type DiscussionProps = {
    ticket: ITicket;
    highlightText?: string;
    events?: CommentEvents;
    selectedCommentId?: number;
    commentIdToFocusOn?: number;
    onUntag?: (comment: IComment) => void;
    onSelectComment?: (commentId: IComment['id']) => void;
    onDeselectComment?: () => void;
};

const _Date = styled(Box)`
    position: sticky;
    top: ${({ theme }) => theme.spacing(1)};
    z-index: 1;
    display: flex;
    margin-top: ${({ theme }) => theme.spacing(1)};
    margin-bottom: 0;
    justify-content: center;
`;

const _DateLabel = styled(Typography)`
    border: 1px solid #ddd;
    padding: ${({ theme }) => theme.spacing(0, 1)};
    border-radius: 100px;
    background: #fff;
`;

const _ProgressContainer = styled(Box)`
    width: '100%';
    display: 'flex';
    justify-content: 'center';
`;

type CommentGroupProps = DiscussionProps & {
    date: string;
    items: (ITicketTrailEvent | IComment | LlmTrailEvent)[];
    lastEvent?: ITicketTrailEvent | LlmTrailEvent;
};

const CommentGroup = ({
    date,
    lastEvent,
    events,
    items,
    highlightText,
    ticket,
    selectedCommentId,
    commentIdToFocusOn,
    onSelectComment,
    onDeselectComment,
    onUntag,
}: CommentGroupProps) => {
    return (
        <Box>
            <_Date>
                <_DateLabel variant="caption">{date}</_DateLabel>
            </_Date>
            {!lastEvent && events?.isLoading && (
                <_ProgressContainer id="events-loading">
                    <CircularProgress />
                </_ProgressContainer>
            )}
            {items.map((item, at) => {
                const _isComment = isComment(item);
                return (
                    <React.Fragment key={item.id}>
                        {_isComment && (
                            <Comment
                                comment={item as IComment} // Cast item to IComment
                                highlightText={highlightText}
                                title={item.is_customer ? ticket.subject : undefined}
                                selected={item.id === selectedCommentId}
                                focusedOn={item.id === commentIdToFocusOn}
                                onSelect={onSelectComment}
                                onDeselect={onDeselectComment}
                                onUntag={onUntag}
                                preciseTimestamp={!!events?.data}
                            />
                        )}
                        {!_isComment && (
                            <>
                                <EventItem
                                    event={item}
                                    sx={{ mb: at === items.length - 1 || isComment(items[at + 1]) ? 2 : 0 }}
                                />
                                {lastEvent?.id === item.id && events?.isLoading && (
                                    <_ProgressContainer id="events-loading">
                                        <CircularProgress />
                                    </_ProgressContainer>
                                )}
                            </>
                        )}
                    </React.Fragment>
                );
            })}
        </Box>
    );
};

export default CommentGroup;

import { Box, BoxProps, List, styled, Switch, SxProps, Tooltip, Typography, useTheme } from '@mui/material';
import { IComment, IPolicySet } from '@tymely/atoms';
import { CommentHighlight, highlightHtml } from '@tymely/components/Comment';
import format from 'date-fns/format';
import React from 'react';
import { useAppMode, useCommentToggleIsCustomer, useResetComment } from '@tymely/services';
import { useIsMutating } from '@tanstack/react-query';
import { ArrawIcon } from '@tymely/components/Menu/NavMenu';
import root from 'react-shadow';
import MarkdownIt from 'markdown-it';

import Attachments from './Attachments';
import { linkRegexp, sanitizeHtml } from './utils';
import { CommentChip, CurrentLabel, InfoButton, InfoListItem, BubbleContainer } from './styled';

const md = new MarkdownIt({
    breaks: true,
    linkify: true,
    typographer: true,
});

type CommentBubbleProps = BoxProps & {
    comment: IComment;
    highlightText?: string;
    intent?: IPolicySet;
    title?: string;
    withDate?: boolean;
    selected?: boolean;
    live?: boolean;
    focusedOn?: boolean;
    showInfo?: boolean;
    htmlProps?: { ref?: React.RefObject<HTMLDivElement>; sx: SxProps };
    onUntag?: (comment: IComment) => void;
};

const HTML_SX: SxProps = {
    position: 'relative',
    overflowWrap: 'anywhere',
    blockquote: { marginBlock: 0, marginInline: 0 },
    pre: { whiteSpace: 'break-spaces' },
};

const _Main = styled(Box)`
    display: flex;
`;

const _InfoList = styled(List)`
    border-left: 1px solid #dbdded;
    margin-bottom: ${({ theme }) => theme.spacing(2)};
    margin-left: ${({ theme }) => theme.spacing(4)};
`;

const _Details = styled(Box)`
    position: relative;
    width: 100%;
`;

const _Attachments = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const _AttachmentsContainer = styled(Box)`
    display: flex;
    align-items: center;
    margin-right: ${({ theme }) => theme.spacing(1)};
`;

const _AttachmentsLabel = styled(Typography)`
    margin-right: ${({ theme }) => theme.spacing(0.5)};
`;

const _InternalLabel = styled(Typography)`
    color: ${({ theme }) => theme.palette.warning.dark};
`;

const _Header = styled(Box)`
    display: flex;
    flex-direction: column;
    margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const _SwitchContainer = styled(Box)`
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    margin-top: ${({ theme }) => theme.spacing(-1)};
    margin-right: ${({ theme }) => theme.spacing(-1)};
`;

const CommentBubble = ({
    withDate,
    comment,
    selected,
    focusedOn,
    htmlProps,
    showInfo,
    onUntag,
    highlightText,
    live,
    intent,
    title,
    children,
    ...rest
}: CommentBubbleProps) => {
    const html = comment.body_html || md.render(comment.body || '').replace(/\n+/g, '');
    const highlights = (comment.additional_data?.['highlights'] || []) as CommentHighlight[];
    const attachments = comment.attachment_links?.filter((link) => linkRegexp.test(link)) || [];

    const isBodyEmpty = React.useMemo(() => !html.trim(), [html]);
    const bodyHtml = React.useMemo(() => {
        const highlightList = [...highlights];
        if (highlightText) {
            highlightList.push({
                token: highlightText,
                class: 'highlight-text',
                textMode: true,
                global: true,
            });
        }
        const result = highlightHtml(highlightList, html);
        return comment.is_customer ? sanitizeHtml(result) : result;
    }, [comment, highlights, highlightText, html, attachments]);

    const [infoOpen, setInfoOpen] = React.useState(showInfo || false);
    const [attachOpen, setAttachOpen] = React.useState(isBodyEmpty);
    const htmlSx: SxProps = { ...HTML_SX, ...htmlProps?.sx };

    // Cannot use normal styling here b/c of Shadow DOM
    const theme = useTheme();
    const htmlElem = (
        <Box
            ref={htmlProps?.ref}
            sx={htmlSx}
            className="content"
            style={htmlSx as React.CSSProperties}
            dangerouslySetInnerHTML={{
                __html:
                    bodyHtml +
                    `<style>
                        .content img { max-width: 800px; max-height: 800px;}
                        .content .highlight-text{background-color:${theme.palette.primary.main};}
                        .content table {border-collapse:collapse;}
                        .content a {text-decoration: underline !important; color: ${theme.palette.info.main} !important;}
                        .content pre {white-space: pre-wrap;}
                    </style>`,
            }}
        />
    );

    const { mutateAsync: resetComment } = useResetComment(comment);
    const isResettingComment = useIsMutating({ mutationKey: ['resetComment', comment?.id] }) > 0;

    const { isOnline } = useAppMode();
    const toggleIsCustomer = useCommentToggleIsCustomer();

    const disabled = comment.is_customer && isOnline && !focusedOn;

    const dom = (
        <BubbleContainer comment={comment} live={live} focusedOn={focusedOn} disabled={disabled} {...rest}>
            <_Main>
                <_Details>
                    <_Header>
                        <_SwitchContainer>
                            {selected && <CurrentLabel />}
                            {intent && (
                                <CommentChip
                                    label={intent.name}
                                    disabled={isResettingComment}
                                    onDelete={(event) => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                        resetComment();
                                    }}
                                />
                            )}
                            {showInfo && <InfoButton open={infoOpen} onToggle={setInfoOpen} />}
                            <Switch
                                color={comment.is_public === false ? 'warning' : 'success'}
                                size="small"
                                checked={!comment.is_customer}
                                onChange={() => toggleIsCustomer?.(comment)}
                                onClick={(event) => event.stopPropagation()}
                            />
                        </_SwitchContainer>
                        {(title || comment.agent_username) && (
                            <Box display="flex" alignItems="flex-end" mt={1}>
                                {title && (
                                    <Typography fontWeight={500} flex={1}>
                                        {title}
                                    </Typography>
                                )}
                                {comment.agent_username && (
                                    <Typography ml="auto" variant="caption" sx={{ whiteSpace: 'nowrap' }}>
                                        Labeled by: <b>{comment.agent_username}</b>
                                    </Typography>
                                )}
                            </Box>
                        )}
                    </_Header>
                    {html && (comment.body_html ? <root.div>{htmlElem}</root.div> : htmlElem)}
                    {children}
                    <_Attachments>
                        {attachments.length > 0 && (
                            <_AttachmentsContainer
                                onClick={(event) => {
                                    event.stopPropagation();
                                    event.preventDefault();
                                    if (!isBodyEmpty) {
                                        setAttachOpen((open) => !open);
                                    }
                                }}
                            >
                                <_AttachmentsLabel variant="subtitle2" color="secondary">
                                    Attachments ({attachments.length})
                                </_AttachmentsLabel>
                                {!isBodyEmpty && <ArrawIcon open={attachOpen} fontSize={0} color="text.secondary" />}
                            </_AttachmentsContainer>
                        )}
                        {comment.is_public === false && <_InternalLabel variant="body2">Internal</_InternalLabel>}
                    </_Attachments>
                </_Details>
                {infoOpen && (
                    <_InfoList>
                        <InfoListItem label="Name" value={comment.from_name} />
                        <InfoListItem label="Email" value={comment.from_email} />
                        <InfoListItem label="Country Code" value={comment.additional_data['country_code']} />
                        <InfoListItem
                            label="Sent"
                            value={format(new Date(comment.inquiry_date), 'HH:mm, MMM dd, yyyy')}
                        />
                        <InfoListItem label="Type of issue" value={comment.additional_data['issue_type']} />
                    </_InfoList>
                )}
            </_Main>
            {attachOpen && <Attachments attachments={attachments} />}
        </BubbleContainer>
    );
    return disabled ? (
        <Tooltip title="This comment is read-only. Please move to the latest comment." placement="right">
            {dom}
        </Tooltip>
    ) : (
        dom
    );
};

export default CommentBubble;

import { useApi } from '@tymely/api';
import { ITicket } from '@tymely/atoms';
import { useInfiniteQuery } from '@tanstack/react-query';
import { LlmTrailEvent } from '@tymely/atoms/llmTrail.atoms';

export const LLM_TRAIL_EVENTS_QUERY_KEY = 'llmTrailEvents';

export const useLlmTrailEventsQuery = (ticketId: ITicket['id'], enabled?: boolean) => {
    const api = useApi();
    return useInfiniteQuery<LlmTrailEvent[]>({
        queryKey: [LLM_TRAIL_EVENTS_QUERY_KEY, ticketId],
        queryFn: ({ pageParam }) => api.get(`/ticket/${ticketId}/llm-trail`, { params: { skip: pageParam } }),
        getNextPageParam: (lastPage, pages) => {
            if (lastPage.length === 0) return undefined;
            return pages.reduce((total, page) => total + page.length, 0);
        },
        enabled,
    });
};

import React from 'react';
import { TemplateError } from '@tymely/api';
import { useArgumentsQuery, useRenderTemplateQuery, useTicketOfflineQuery } from '@tymely/services';
import { Option } from '@tymely/components';
import { Nullable } from '@global/types';
import { useDebounce } from '@tymely/utils';
import { IResponseTemplate, ITemplateVariable, isCategorical } from '@tymely/atoms';

type UseCreateTemplateEditorArgs = {
    templateId?: IResponseTemplate['id'];
    template?: IResponseTemplate['response_txt'];
    isModified?: boolean;
    diff?: Diff.Change[];
    sanitize?: boolean;
    error?: TemplateError;
    onChange?: (template: IResponseTemplate['response_txt'], varNames: string[]) => void;
};

const useCreateTemplateEditor = ({
    template,
    sanitize,
    error,
    onChange,
    isModified,
    ...rest
}: UseCreateTemplateEditorArgs) => {
    const [variables, setVariables] = React.useState<Record<string, ITemplateVariable>>({});
    const [comment, setComment] = React.useState<Nullable<Option<number>[]>>([]);
    const [ticketId, setTicketId] = React.useState<number>();

    React.useEffect(() => {
        const unloadCallback = (event: BeforeUnloadEvent) => {
            event.preventDefault();
            event.returnValue = '';
            return '';
        };

        if (isModified) {
            window.addEventListener('beforeunload', unloadCallback);
            return () => window.removeEventListener('beforeunload', unloadCallback);
        }
        return;
    }, [isModified]);

    const ticketOfflineQuery = useTicketOfflineQuery(ticketId, !!ticketId);

    const argumentsQuery = useArgumentsQuery({
        commentId: comment ? comment[0]?.value : undefined,
        argsVersion: undefined,
        intentArgsOnly: false,
    });

    const argsVariables = React.useMemo(() => {
        if (!argumentsQuery.data) {
            return variables;
        }
        return argumentsQuery.data.reduce(
            (vars, arg) => {
                vars[arg.name] = {
                    ...arg,
                    ...vars[arg.name],
                    categories: isCategorical(arg) ? arg.categories : undefined,
                    value: vars[arg.name]?.value || arg.value,
                };
                return vars;
            },
            { ...variables },
        );
    }, [variables, argumentsQuery.data]);

    const templateToRender = useDebounce(template, 300) || '';
    const renderTemplateQuery = useRenderTemplateQuery(
        templateToRender,
        argsVariables,
        sanitize,
        Boolean(templateToRender) && !argumentsQuery.isLoading && !ticketOfflineQuery.isLoading,
    );

    const templateErrors = React.useMemo(() => {
        if (renderTemplateQuery.error instanceof TemplateError) {
            return renderTemplateQuery.error.detail;
        }
        if (error) {
            return error.detail;
        }
        return [];
    }, [error, renderTemplateQuery.error]);

    const onCodeChange = (code: string) => {
        onChange?.(code, Object.keys(argsVariables));
    };

    React.useEffect(() => {
        if (template) {
            // Trigger onChange when variables fetched (after template changed)
            onCodeChange(template);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [argsVariables, template]);

    return {
        ticketOfflineQuery,
        argumentsQuery,
        renderTemplateQuery,
        ticketId,
        templateErrors,
        setTicketId,
        variables,
        argsVariables,
        comment,
        setComment,
        setVariables,
        onCodeChange,
        template,
        ...rest,
    };
};

type TemplateEditorContext = ReturnType<typeof useCreateTemplateEditor>;

const editorContext = React.createContext<TemplateEditorContext | undefined>(undefined);

export type TemplateEditorProviderProps = React.PropsWithChildren<UseCreateTemplateEditorArgs>;

const TemplateEditorProvider = ({ children, ...rest }: TemplateEditorProviderProps) => {
    const value = useCreateTemplateEditor(rest);
    return <editorContext.Provider value={value}>{children}</editorContext.Provider>;
};

export const useTemplateEditor = () => {
    const context = React.useContext(editorContext);
    if (!context) {
        throw Error('`useTemplateEditor` must be used only inside `TemplateEditorProvider`');
    }
    return context;
};

export default TemplateEditorProvider;

import {
    IArgument,
    IArgumentCategory,
    IArgumentDisplayRegulator,
    IArgumentMultiCategory,
    IArgumentString,
    ICategoryItem,
    isCategorical,
    isNestedCategorical,
} from '@tymely/atoms';
import React from 'react';

import { ArgumentFieldProps } from '../ArgumentTypes/Layout';
import * as Args from '../ArgumentTypes';

export type ArgEditorProps = {
    argument: IArgument;
    disabled?: boolean;
    loading?: boolean;
    groupArgument?: IArgumentCategory;
    displayRegulatorArgument?: IArgumentDisplayRegulator;
    searchArgument?: IArgumentString;
    onChange?: ArgumentFieldProps<IArgument>['onChange'];
    setHighlightText?: (text: string) => void;
};

const ArgEditor = React.memo(
    ({
        argument,
        disabled,
        loading,
        groupArgument: groupArg,
        displayRegulatorArgument,
        searchArgument,
        onChange,
        setHighlightText,
    }: ArgEditorProps) => {
        const props = {
            onChange,
            disabled: disabled || !onChange,
            loading,
        };

        if (isCategorical(argument)) {
            if (groupArg && isNestedCategorical(argument)) {
                return (
                    <Args.GroupedMultiCategoryArgument
                        {...props}
                        argument={argument as IArgumentMultiCategory<ICategoryItem>}
                        groupArg={groupArg as IArgumentCategory}
                        displayRegulatorArg={displayRegulatorArgument}
                        searchArgument={searchArgument}
                    />
                );
            }

            const onChange = (arg: (typeof argument)[]) => props.onChange?.(arg) as Promise<(typeof argument)[]>;
            return <Args.MultiCategoryArgument {...props} onChange={onChange} argument={argument} />;
        }

        switch (argument.dtype) {
            case 'bool':
            case 'bool|None':
            case 'bool | None':
                return <Args.BooleanArgument {...props} argument={argument} />;
            case 'list[Image]':
            case 'Image':
                return <Args.ImageArgument {...props} argument={argument} />;
            case 'list[Url]':
            case 'Url':
                return <Args.UrlArgument {...props} argument={argument} />;
            case 'list[VideoUrl]':
            case 'VideoUrl':
                return <Args.VideoArgument {...props} argument={argument} />;
            case 'AddressStr':
                return <Args.AddressArgument {...props} argument={argument} />;
            case 'int':
            case 'float':
            case 'str':
            case 'str|None':
            case 'str | None':
            case 'EmailStr':
                return <Args.InputFieldArgument {...props} argument={argument} setHighlightText={setHighlightText} />;
            case 'datetime':
                return <Args.DateTimeArgument {...props} argument={argument} />;
            default:
                return <Args.ObjectArgument {...props} argument={argument} />;
        }
    },
);

ArgEditor.displayName = 'ArgEditor';

export default ArgEditor;

/*
    This module is a work in progress, all the commented code will be removed/refactored in following releases.
 */
import * as Diff from 'diff';
import React, { useCallback, useState } from 'react';
import { githubLight } from '@uiw/codemirror-theme-github';
import { Diagnostic, linter, lintGutter } from '@codemirror/lint';
import CodeMirror, { BasicSetupOptions, ReactCodeMirrorProps, ReactCodeMirrorRef } from '@uiw/react-codemirror';
import { StateEffect, Range, StateField } from '@codemirror/state';
import { langs } from '@uiw/codemirror-extensions-langs';
import { Decoration, EditorView } from '@codemirror/view';
import { api, ITemplateError } from '@tymely/api';
import { autocompletion, CompletionContext } from '@codemirror/autocomplete';
import { syntaxTree } from '@codemirror/language';
import { useDropzone } from 'react-dropzone';
import { Loader } from '@tymely/components';

const BASIC_SETUP: BasicSetupOptions = {
    autocompletion: true,
    syntaxHighlighting: true,
    foldGutter: true,
    tabSize: 4,
};

const buildAutocomplete = (argNames: string[]) => (context: CompletionContext) => {
    const nodeBefore = syntaxTree(context.state).resolveInner(context.pos, -1);
    let tmpNode: typeof nodeBefore | null = nodeBefore;
    let varValue = '';
    while (tmpNode && tmpNode.name === 'variableName') {
        const value = context.state.sliceDoc(tmpNode.from, tmpNode.to);
        if (!value.trim()) break;
        varValue = value.toLowerCase() + varValue;
        tmpNode = tmpNode.prevSibling;
    }

    if (!varValue) return null;

    const tagOptions = argNames
        .filter((name) => name.toLowerCase().startsWith(varValue))
        .map((name) => ({ label: name, type: 'variable' }));

    return {
        from: context.pos - varValue.length,
        options: tagOptions,
    };
};

const jinjaLinter =
    (errors: ITemplateError[]) =>
    (view: EditorView): Diagnostic[] =>
        errors.map((err) => {
            const from = view.state.doc.line(err.location?.line ?? 1).from;
            return {
                from: from,
                to: from,
                message: err.description,
                severity: 'error',
            };
        });

export type JinjaCodeEditorProps = ReactCodeMirrorProps & {
    errors?: ITemplateError[];
    diff?: Diff.Change[];
    varNames?: string[];
};

const JinjaCodeEditor = ({
    diff,
    errors,
    value,
    varNames,
    onChange,
    height,
    width,
    style,
    ...rest
}: JinjaCodeEditorProps) => {
    const _ref = React.useRef<ReactCodeMirrorRef>(null);

    const varsAutomplete = React.useMemo(() => buildAutocomplete(varNames || []), [varNames]);

    const code = React.useMemo(() => {
        return diff?.map((chunk) => chunk.value).join('') ?? value;
    }, [value, diff]);

    const highlightEffect = React.useMemo(() => StateEffect.define<Range<Decoration>[]>(), []);

    const highlightExtension = React.useMemo(() => {
        return StateField.define({
            create() {
                return Decoration.none;
            },
            update(value, transaction) {
                value = value.map(transaction.changes);
                for (const effect of transaction.effects) {
                    if (effect.is(highlightEffect)) value = value.update({ add: effect.value, sort: true });
                }
                return value;
            },
            provide: (f) => EditorView.decorations.from(f),
        });
    }, []);

    React.useEffect(() => {
        if (_ref.current?.view && _ref.current?.state && diff) {
            let at = 0;
            for (const chunk of diff) {
                if (chunk.added) {
                    const decoration = Decoration.mark({
                        attributes: { style: 'background-color:#65E2AE; color: white' },
                    });
                    _ref.current?.view.dispatch({
                        effects: highlightEffect.of([decoration.range(at, at + chunk.value.length)]),
                    });
                } else if (chunk.removed) {
                    const decoration = Decoration.mark({
                        attributes: { style: 'background-color:#FE7CA3; color: white' },
                    });
                    _ref.current?.view.dispatch({
                        effects: highlightEffect.of([decoration.range(at, at + chunk.value.length)]),
                    });
                }
                at = at + chunk.value.length;
            }
        }
    }, [_ref.current, code, diff]);

    const extensions = React.useMemo(
        () => [
            lintGutter(),
            langs.jinja2(),
            linter(jinjaLinter(errors ?? []), {
                delay: 0,
                markerFilter: (diags) => diags.filter((diag) => diag.from !== diag.to),
            }),
            autocompletion({ override: [varsAutomplete] }),
            highlightExtension,
            EditorView.lineWrapping,
            EditorView.theme({
                '.cm-diagnostic': {
                    maxWidth: '800px',
                    maxHeight: '500px',
                    overflow: 'auto',
                },
            }),
        ],
        [highlightExtension, errors],
    );

    const [isProcessing, setIsProcessing] = useState(false);

    const uploadFileToS3 = async (file: File) => {
        try {
            const formData = new FormData();
            formData.append('file', file);
            const response = (await api.post('s3/upload-public-file', formData)) as {
                public_url: string;
            };

            if (!response || !response.public_url) {
                throw new Error(`Failed to upload file to S3: ${file.name}`);
            }

            return response.public_url;
        } catch (err) {
            console.error('Failed to upload a file to S3:', err);
            throw err;
        }
    };

    const onDrop = useCallback(
        async (acceptedFiles: File[]) => {
            if (!acceptedFiles.length) return;
            setIsProcessing(true);
            try {
                const file = acceptedFiles[0];
                const publicUrl = await uploadFileToS3(file);
                if (_ref.current?.view && _ref.current?.state) {
                    const editorView = _ref.current.view;
                    const cursorPosition = editorView.state.selection.main.head || editorView.state.doc.length;
                    editorView.dispatch({
                        changes: {
                            from: cursorPosition,
                            insert: publicUrl,
                        },
                    });
                }
            } catch (err) {
                console.error('Error during file upload:', err);
            } finally {
                setIsProcessing(false);
            }
        },
        [_ref],
    );

    const { getRootProps, getInputProps } = useDropzone({
        noClick: true,
        onDrop,
        accept: {
            'image/*': [],
        },
        maxFiles: 1,
    });

    return (
        <div {...getRootProps()} style={{ position: 'relative', height: '100%', width: '100%', overflow: 'hidden' }}>
            <input {...getInputProps()} />
            <CodeMirror
                ref={_ref}
                value={code}
                theme={githubLight}
                readOnly={!onChange || !!diff}
                extensions={extensions}
                editable={!!onChange}
                onChange={onChange}
                basicSetup={BASIC_SETUP}
                style={{ height, width, ...style }}
                height={height}
                width={width}
                {...rest}
            />
            {isProcessing && (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 10,
                    }}
                >
                    <Loader />
                </div>
            )}
        </div>
    );
};

export default JinjaCodeEditor;

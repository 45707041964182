import { AtomEffect, DefaultValue } from 'recoil';
import { OrganizationDataset } from '@tymely/types/dataset';

export const localStorageEffect =
    <T>(key: string): AtomEffect<T> =>
    ({ setSelf, onSet }) => {
        const savedValue = localStorage.getItem(`tymely-${key}`);
        if (savedValue !== null) {
            try {
                setSelf((JSON.parse(savedValue) as T) || new DefaultValue());
            } catch {
                setSelf(new DefaultValue());
            }
        }

        onSet((newValue, _, isReset) => {
            isReset
                ? localStorage.removeItem(`tymely-${key}`)
                : localStorage.setItem(`tymely-${key}`, JSON.stringify(newValue));
        });
    };

export const loadDatasets = (): OrganizationDataset => {
    const savedDatasets = localStorage.getItem('datasets');
    return savedDatasets ? (JSON.parse(savedDatasets) as OrganizationDataset) : {};
};

export const saveDatasets = (datasets: OrganizationDataset) => {
    localStorage.setItem('datasets', JSON.stringify(datasets));
};

import { JsonView as _JsonView, Props as _JsonViewProps, defaultStyles } from 'react-json-view-lite';

import 'react-json-view-lite/dist/index.css';

const jsonStyle = {
    ...defaultStyles,
    container: 'json-view-container',
};

export default function JsonView(props: _JsonViewProps) {
    return <_JsonView style={jsonStyle} {...props} />;
}
